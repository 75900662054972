(function () {
    'use strict';
    
    
    var env = {};
    if(window){  
        Object.assign(env, window._env);
    }
    
    angular
        .module('budget.bimiboo.com', [
            'ui.router', 'ngSanitize', 'templates', 'bw.paging', 'schemaForm', 'ui.ace', 'treeControl', 'ui.sortable'
        ])
        .constant('_env', env)
        .config(httpProviderConfig)
        .config(routeProviderConfig)
        .run(run);
    
    function httpProviderConfig($httpProvider) {
        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection) {
                    var defer = $q.defer();
                    if(rejection.status == 401) {
                        window.location.assign('#/login');
                    }
                    defer.reject(rejection);

                    return defer.promise;
                },
                'request': function(config) {
                    config.headers = config.headers || {};
                    if (localStorage.getItem('token')) {
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
                    }
                    return config || $q.when(config);
                }
            };
        });
    }

    function routeProviderConfig($stateProvider, $urlRouterProvider) {
        
        $urlRouterProvider
            .when('', '/expenses')
            .otherwise('/expenses');

        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'templates/login/view.html',
                controller: 'LoginCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: true
                }
            })
            .state('home', {
                url: '',
                templateUrl: 'templates/home/view.html',
                controller: 'HomeCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.expenses', {
                url: '/expenses',
                templateUrl: 'templates/expenses/view.html',
                controller: 'ExpensesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.transactions', {
                url: '/transactions',
                templateUrl: 'templates/transactions/view.html',
                controller: 'TransactionsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.reports', {
                url: '/reports',
                templateUrl: 'templates/reports/overview/view.html',
                controller: 'ReportsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.monthly-reports', {
                url: '/monthly_reports/:departments/:month/:type/:currency/:expenseCategoryId',
                templateUrl: 'templates/reports/monthly/view.html',
                controller: 'MonthlyReportCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.expense-categories', {
                url: '/expense-categories',
                templateUrl: 'templates/expense-categories/view.html',
                controller: 'ExpenseCategoriesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.services', {
                url: '/services',
                templateUrl: 'templates/services/view.html',
                controller: 'ServicesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.bank-accounts', {
                url: '/bank-accounts',
                templateUrl: 'templates/bank-accounts/view.html',
                controller: 'BankAccountsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.staff', {
                url: '/staff',
                templateUrl: 'templates/staff/view.html',
                controller: 'StaffCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.salaries', {
                url: '/salaries',
                templateUrl: 'templates/salaries/view.html',
                controller: 'SalariesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.users', {
                url: '/users',
                templateUrl: 'templates/users/view.html',
                controller: 'UsersCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            });

    }

    function run($rootScope, $timeout) {

        $rootScope.formatDate = formatDate;
        $rootScope.get_cs = get_cs;

        $rootScope.$on('$routeChangeStart', function(event, next, current) {

            if (next.restrictions.ensureAuthenticated) {
                if (!localStorage.getItem('token')) {
                    $location.path('/login');
                }
            }

            if (next.restrictions.loginRedirect) {
                if (localStorage.getItem('token')) {
                    $location.path('/home');
                }
            }

        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $timeout(function () {
                // fix sidebar
                var neg = $('.main-header').outerHeight() + $('.main-footer').outerHeight()
                var window_height = $(window).height()
                var sidebar_height = $('.sidebar').height()

                if ($('body').hasClass('fixed')) {
                    $('.content-wrapper, .right-side').css('min-height', window_height - $('.main-footer').outerHeight())
                } else {
                    if (window_height >= sidebar_height) {
                        $('.content-wrapper, .right-side').css('min-height', window_height - neg)
                    } else {
                        $('.content-wrapper, .right-side').css('min-height', sidebar_height)
                    }
                }
            })
        })
    }
    
})();
