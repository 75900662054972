(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('TransactionsCtrl', TransactionsCtrl);

    function TransactionsCtrl($scope, $state, $location, $http, AuthenticationService) {
        $scope.numeral = numeral;
        $scope.curTab = 'list';

        $scope.canManageTransactions = function() {
            return AuthenticationService.user.can('manage-transactions');
        }

        $scope.canAdditionalActons = function() {
            return AuthenticationService.user.can('manage-additional-actions');
        }

        $scope.excludeCompletedTransits = true;
        $scope.excludeCompletedTransitsFilter = function (transaction) {
            if($scope.excludeCompletedTransits && transaction.balance <= 0) return false;

            return true;
        };

        $scope.excludeCompletedLoans = true;
        $scope.excludeCompletedLoansFilter = function (transaction) {
            if($scope.excludeCompletedLoans && transaction.balance <= 0) return false;

            return true;
        };

        $scope.excludeStaffTransactions = false;
        $scope.excludeStaffTransactionsFilter = function (transaction) {
            if($scope.excludeStaffTransactions && transaction.expense_category_id == 1) return false;

            return true;
        };

        getDepartments(function () {
            getExpenseCategories(function () {
                getBanks();
            })
        });

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {

                $scope.departments = response.data;

                if (callback) callback();
            });
        }

        function getExpenseCategories(callback) {
            $http.get(_env.apiUrl + '/expense_categories').then(function (response) {

                $scope.expense_categories = response.data;

                if (callback) callback();
            });
        }

        function getBanks(callback) {
            $http.get(_env.apiUrl + '/banks').then(function (response) {

                $scope.banks = response.data;

                if (callback) callback();
            });
        }
    }

})();