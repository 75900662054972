(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('StaffCtrl', StaffCtrl);

    function StaffCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        $scope.numeral = numeral;

        $scope.auth = AuthenticationService;

        function getEmployees() {
            $http.get(_env.apiUrl + '/employees').then(function (response) {
                $scope.employees = response.data;
            });
        }

        function getDepartments() {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;
            });
        }

        function getBanks(callback) {
            $http.get(_env.apiUrl + '/banks').then(function (response) {

                $scope.banks = response.data;

                if (callback) callback();
            });
        }

        getEmployees();
        getDepartments();
        getBanks();

        $scope.onBankChanged = function() {
            if ($scope.editableEmployee) {
                $scope.editableEmployee.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editableEmployee.bank_id;
                });
                if ($scope.editableEmployee.bank.accounts.length == 0) {
                    $scope.editableEmployee.account = null;
                    $scope.editableEmployee.account_id = null;
                }
                if ($scope.editableEmployee.bank.accounts.length == 1) {
                    $scope.editableEmployee.account = $scope.editableEmployee.bank.accounts[0];
                    $scope.editableEmployee.account_id = $scope.editableEmployee.bank.accounts[0].id;

                    if ($scope.editableEmployee.account.payment_methods.length == 0) {
                        $scope.editableEmployee.payment_method_id = null;
                    }

                    if ($scope.editableEmployee.account.payment_methods.length == 1) {
                        $scope.editableEmployee.payment_method_id = $scope.editableEmployee.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function() {
            if ($scope.editableService) {
                $scope.editableService.account = _.find($scope.editableService.bank.accounts, function (account) {
                    return account.id == $scope.editableService.account_id;
                });

                if ($scope.editableService.account.payment_methods.length == 0) {
                    $scope.editableService.payment_method_id = null;
                }

                if ($scope.editableService.account.payment_methods.length == 1) {
                    $scope.editableService.payment_method_id = $scope.editableService.account.payment_methods[0].id;
                }
            }
        }

        $scope.editEmployee = function(employee) {

            if (employee) {
                $scope.editableEmployee = angular.copy(employee);
                _.forEach($scope.editableEmployee.salary_rates, function (salaryRate) {
                    salaryRate.date = moment(salaryRate.date).format('DD/MM/YYYY');
                });
                $scope.onEmployeeDepartmentChanged();

                $scope.editableEmployee.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editableEmployee.bank_id;
                });

                $scope.editableEmployee.account = _.find($scope.editableEmployee.bank.accounts, function (account) {
                    return account.id == $scope.editableEmployee.account_id;
                });
            } else {
                $scope.editableEmployee = {}
            }

            $('#modal-default').modal('show');
        }

        $scope.onEmployeeDepartmentChanged = function() {
            if (!$scope.editableEmployee) return;

            $scope.editableEmployee.department = _.find($scope.departments, function (d) {
                return d.id == $scope.editableEmployee.department_id;
            });
        }

        $scope.addSalaryRate = function () {
            if (!$scope.editableEmployee.salary_rates) {
                $scope.editableEmployee.salary_rates = [];
            }
            $scope.editableEmployee.salary_rates.unshift({
                date: moment().format('DD/MM/YYYY'),
                salary: 0,
                card: 0
            })
        }

        $scope.deleteSalaryRate = function(atIndex) {
            $scope.editableEmployee.salary_rates.splice(atIndex, 1);
        }

        $scope.saveEmployee = function () {
            if (!$scope.editableEmployee) return;

            if (!$scope.editableEmployee.name) {
                error('Name is required');
                return;
            }

            if (!$scope.editableEmployee.email) {
                error('Email is required');
                return;
            }

            if (!$scope.editableEmployee.department_id) {
                error('Cost center is required');
                return;
            }

            if (!$scope.editableEmployee.expense_category_id) {
                error('Expense is required');
                return;
            }

            if (!$scope.editableEmployee.bank_id) {
                error('Bank is required');
                return;
            }

            if ($scope.editableEmployee.bank.accounts.length > 0 && !$scope.editableEmployee.account_id) {
                error('Bank Account is required');
                return;
            }

            if ($scope.editableEmployee.account && $scope.editableEmployee.account.payment_methods.length > 0 && !$scope.editableEmployee.payment_method_id) {
                error('Payment method is required');
                return;
            }

            if (!$scope.editableEmployee.salary_rates || $scope.editableEmployee.salary_rates.length == 0) {
                error('Salary rate is required');
                return;
            }

            var data = {
                email: $scope.editableEmployee.email,
                name: $scope.editableEmployee.name,
                department_id: $scope.editableEmployee.department_id,
                bank_id: $scope.editableEmployee.bank_id,
                account_id: $scope.editableEmployee.account_id,
                payment_method_id: $scope.editableEmployee.payment_method_id,
                expense_category_id: $scope.editableEmployee.expense_category_id,
                salaryRates: []
            };
            _.forEach($scope.editableEmployee.salary_rates, function (salaryRate) {
                data.salaryRates.push({
                    date: moment(salaryRate.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    salary: salaryRate.salary,
                    card: salaryRate.card
                });
            });

            $scope.proccessing = true;

            if ($scope.editableEmployee.id) {
                $http.put(_env.apiUrl + '/employees/' + $scope.editableEmployee.id, data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getEmployees();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/employees', data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getEmployees();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteEmployee = function (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/employees/' + id).then(function () {
                        getEmployees();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        function getCurrencyRates() {
            $http.get(_env.apiUrl + '/currency-rates').then(function (response) {
                $scope.currencyRates = response.data;
            });
        }
        getCurrencyRates();

        $scope.showEditableCurrencyRateForm = function(currencyRate) {
            if (currencyRate) {
                $scope.editableCurrencyRate = angular.copy(currencyRate);
                $scope.editableCurrencyRate.date = moment($scope.editableCurrencyRate.date).format('DD/MM/YYYY');
            } else {
                $scope.editableCurrencyRate = {
                    date: moment().format('DD/MM/YYYY')
                };

            }

            $('#currency-rate-modal').modal('show');
        }

        $scope.saveCurrencyRate = function () {
            if (!$scope.editableCurrencyRate) return;

            if (!$scope.editableCurrencyRate.date) {
                return;
            }

            if (!$scope.editableCurrencyRate.amount) {
                return;
            }

            var data = {
                date: moment($scope.editableCurrencyRate.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                amount: $scope.editableCurrencyRate.amount
            };

            $scope.proccessing = true;

            if ($scope.editableCurrencyRate.id) {
                $http.put(_env.apiUrl + '/currency-rates/' + $scope.editableCurrencyRate.id, data).then(function(response) {
                    $('#currency-rate-modal').modal('hide');
                    getCurrencyRates();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/currency-rates', data).then(function(response) {
                    $('#currency-rate-modal').modal('hide');
                    getCurrencyRates();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteCurrencyRate = function (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/currency-rates/' + id).then(function () {
                        getCurrencyRates();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });

        };

        function getTransportRates() {
            $http.get(_env.apiUrl + '/transport-rates').then(function (response) {
                $scope.transportRates = response.data;
            });
        }

        getTransportRates();

        $scope.showEditableTransportRateForm = function(transportRate) {
            if (transportRate) {
                $scope.editableTransportRate = angular.copy(transportRate);
                $scope.editableTransportRate.date = moment($scope.editableTransportRate.date).format('DD/MM/YYYY');
            } else {
                $scope.editableTransportRate = {
                    date: moment().format('DD/MM/YYYY')
                };
            }

            $('#transport-rate-modal').modal('show');
        }

        $scope.saveTransportRate = function () {
            if (!$scope.editableTransportRate) return;

            if (!$scope.editableTransportRate.date) {
                return;
            }

            if (!$scope.editableTransportRate.amount) {
                return;
            }

            var data = {
                amount: $scope.editableTransportRate.amount,
                date: moment($scope.editableTransportRate.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
            };

            $scope.proccessing = true;

            if ($scope.editableTransportRate.id) {
                $http.put(_env.apiUrl + '/transport-rates/' + $scope.editableTransportRate.id, data).then(function(response) {
                    $('#transport-rate-modal').modal('hide');
                    getTransportRates();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/transport-rates', data).then(function(response) {
                    $('#transport-rate-modal').modal('hide');
                    getTransportRates();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteTransportRate = function (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/transport-rates/' + id).then(function () {
                        getTransportRates();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });

        };
    }
})();