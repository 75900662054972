(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('SalariesCtrl', SalariesCtrl);

    function SalariesCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        $scope.sortable = "name";

        $scope.canAccept = function () {
            return AuthenticationService.user.can('manage-transactions');
        }

        function getBanks(callback) {
            $http.get(_env.apiUrl + '/banks').then(function (response) {

                $scope.banks = response.data;

                if (callback) callback();
            });
        }

        getBanks();

        $scope.filters = {
            period: "this_month",
            onPeriodChanged: function () {
                $scope.excludeVacationDisabled = !isVacationPaymentMonth();
                $scope.getSalaries(true);
            },
            department: 'all',
            onDepartmentFilterChanged: function () {
                $scope.getSalaries(true);
            }
        };

        $scope.numeral = numeral;

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;

                if (callback) callback();
            });
        }

        function getExpenseCategories(callback) {
            $http.get(_env.apiUrl + '/expense_categories').then(function (response) {
                $scope.expenseCategories = response.data;

                if (callback) callback();
            });
        }

        var currencyRates;

        function getCurrencyRates(callback) {
            $http.get(_env.apiUrl + '/currency-rates').then(function (response) {
                currencyRates = _.sortBy(_.map(response.data, function (currencyRate) {
                    currencyRate.date = moment(currencyRate.date)
                    return currencyRate;
                }), function (currencyRate) {
                    return -currencyRate.date;
                });

                callback();
            });
        }

        function getCurrencyRate(date) {
            if (date) {
                date = moment(date);
            } else {
                date = moment();
                if ($scope.filters.period == 'previous_month') {
                    date = date.subtract(1, 'months').endOf('month');
                }
            }

            if (!currencyRates || currencyRates.length == 0) {
                return 0;
            }

            if (currencyRates.length == 1) {
                return currencyRates[0].amount;
            }

            var currencyRate = currencyRates[0].amount;
            for (var i = 0; i < currencyRates.length; i++) {
                var cr = currencyRates[i];
                currencyRate = cr.amount;
                if (cr.date.diff(date) <= 0) {
                    break;
                }
            }

            return currencyRate;
        }


        var transportRates;

        function getTransportRates(callback) {
            $http.get(_env.apiUrl + '/transport-rates').then(function (response) {
                transportRates = _.sortBy(_.map(response.data, function (transportRate) {
                    transportRate.date = moment(transportRate.date)
                    return transportRate;
                }), function (transportRate) {
                    return -transportRate.date;
                });

                if (callback) callback();
            });
        }

        function getTransportRate(date) {
            if (date) {
                date = moment(date);
            } else {
                date = moment();
                if ($scope.filters.period == 'previous_month') {
                    date = date.subtract(1, 'months').endOf('month');
                }
            }

            if (!transportRates || transportRates.length == 0) {
                return 0;
            }

            if (transportRates.length == 1) {
                return transportRates[0].amount;
            }

            var transportRate = transportRates[0].amount;
            for (var i = 0; i < transportRates.length; i++) {
                var cr = transportRates[i];
                transportRate = cr.amount;
                if (cr.date.diff(date) <= 0) {
                    break;
                }
            }

            return transportRate;
        }

        $scope.getSalaries = function (withCache) {
            $http.get(_env.apiUrl + '/salaries?period=' + $scope.filters.period + '&department_id=' + $scope.filters.department + '&with_cache=' + withCache).then(function (response) {

                $scope.employees = response.data;

                var vacationPeriod = getVacationPeriod($scope.filters.period);

                _.forEach($scope.employees, function (employee) {

                    employee.hasUpdate = (typeof employee.hasUpdate != 'undefined') ? employee.hasUpdate : 0;
                    employee.accepted = (typeof employee.accepted != 'undefined') ? employee.accepted : null;

                    if (employee.salary_rates && employee.salary_rates.length > 0) {
                        employee.salaryRates = _.sortBy(_.map(employee.salary_rates, function (salaryRate) {
                            salaryRate.date = moment(salaryRate.date)
                            return salaryRate;
                        }), function (salaryRate) {
                            return -salaryRate.date;
                        });
                    }

                    employee.getSalaryRate = function (date) {
                        if (date) {
                            date = moment(date);
                        } else {
                            date = moment();
                            if ($scope.filters.period == 'previous_month') {
                                date = date.subtract(1, 'months').endOf('month');
                            }
                        }

                        if (!employee.salaryRates || employee.salaryRates.length == 0) {
                            return {
                                salary: 0,
                                card: 0
                            };
                        }

                        if (employee.salaryRates.length == 1) {
                            return employee.salaryRates[0];
                        }

                        var salaryRate = employee.salaryRates[0];
                        for (var i = 0; i < employee.salaryRates.length; i++) {
                            var sr = employee.salaryRates[i];
                            salaryRate = sr;
                            if (sr.date.diff(date) <= 0) {
                                break;
                            }
                        }

                        return salaryRate;
                    }

                    calculateSalaryAndCard(employee);
                    //calculateReimbursement(employee);
                    //calculateOverhead(employee);
                    //calculateVacation(employee, vacationPeriod);
                    //calculateBonuses(employee);
                    //calculatePenalty(employee);

                    employee.details = {};

                    calculatePrepayment(employee);
                    calculateBalance(employee);
                    calculateToBePaid(employee);
                });

                calculateTotal($scope.employees);
            });
        }

        function calculateSalaryAndCard(employee) {
            employee.salary = 0;
            employee.card = 0;

            var m = moment().startOf('month');
            if ($scope.filters.period == 'previous_month') {
                m = m.subtract(1, 'months');
            }
            var daysInMonth = m.daysInMonth();

            var workingDaysInMonth = 0;
            for (var i = 0; i < daysInMonth; i++) {
                var day = m.clone();
                day.add(i, 'day');
                if (day.day() != 0 && day.day() != 6) {
                    workingDaysInMonth++;
                    var salaryRate = employee.getSalaryRate(day);
                    employee.salary += salaryRate.salary;
                    employee.card += salaryRate.card;
                }
            }

            employee.salary = _round(employee.salary / workingDaysInMonth);
            employee.card = _round(employee.card / workingDaysInMonth / getCurrencyRate());
        }

        function calculateReimbursement(employee) {
            employee.reimbursement = 0;

            if (!employee.salariesTypes || !employee.salariesTypes.reimbursement) {
                return;
            }

            employee.reimbursement = employee.salariesTypes.reimbursement;
        }

        function calculateOverhead(employee) {

            var overheads = [];
            employee.overhead = 0;
            if (!employee.salariesTypes || !employee.salariesTypes.overheads) {
                return;
            }
            employee.overhead = employee.salariesTypes.overheads;
        }

        $scope.excludeVacation = false;
        $scope.excludeVacationDisabled = !isVacationPaymentMonth();

        function isVacationPaymentMonth() {
            var date = moment();
            if ($scope.filters.period == 'previous_month') {
                date = date.add(-1, 'month');
            }
            var monthNumber = parseInt(date.format('M'));

            return [2, 5, 8, 11].indexOf(monthNumber) >= 0;
        }

        function calculateVacation(employee, vacationPeriod, forceEndVacationPeriod) {
            employee.vacation = 0;

            if (!employee.salariesTypes || !employee.salariesTypes.vacation) {
                return;
            }

            employee.vacation = employee.salariesTypes.vacation;
        }

        function getVacationPeriod(period) {
            var startDate = moment();
            if (period == 'previous_month') {
                startDate = startDate.add(-1, 'month');
            }
            startDate = startDate.startOf('month');

            var monthNumber = parseInt(startDate.format('M'));

            if ([1, 4, 7, 10].indexOf(monthNumber) >= 0) {
                startDate = startDate.add(-1, 'month');
            } else if ([2, 5, 8, 11].indexOf(monthNumber) >= 0) {
                startDate = startDate.add(-2, 'month');
            }

            var endDate = moment(startDate);
            endDate.add(3, 'month');

            return {
                start: startDate,
                end: endDate,
                days: dateRange(startDate, moment(endDate).add(-1, 'day'))
            };
        }

        function calculateBonuses(employee) {
            employee.bonuses = 0;
            if (!employee.salariesTypes || !employee.salariesTypes.bonuses) {
                return;
            }

            employee.bonuses = employee.salariesTypes.bonuses;
        }

        function calculatePenalty(employee) {
            var penalties = [];
            employee.penalty = 0.0;
            if (!employee.salariesTypes || !employee.salariesTypes.penalty) {
                return;
            }

            employee.penalty = employee.salariesTypes.penalty;
        }

        function calculatePrepayment(employee) {
            employee.prepayment = 0;
            employee.bonuses = 0;

            if (!employee.prepayment_transactions) {
                return;
            }

            var avans = 0;
            var bonuses = 0;

            _.forEach(employee.prepayment_transactions, function (t) {
                switch (t.source) {
                    case 'avans':
                        avans += t.amount;
                        break;
                    case 'bonuses':
                        bonuses += t.amount;
                        break;
                }
            });

            employee.prepayment = avans;
            employee.bonuses = bonuses;
        }

        function calculateBalance(employee) {
            var salary = (typeof employee.salary != 'undefined') ? employee.salary : 0;
            var reimbursement = (typeof employee.reimbursement != 'undefined') ? employee.reimbursement : 0;
            var overhead = (typeof employee.overhead != 'undefined') ? employee.overhead : 0;
            var vacation = (typeof employee.vacation != 'undefined') ? employee.vacation : 0;
            var prepayment = (typeof employee.prepayment != 'undefined') ? employee.prepayment : 0;
            var penalty = (typeof employee.penalty != 'undefined') ? employee.penalty : 0;

            employee.balance = _round(salary + reimbursement + overhead + vacation - prepayment - penalty);
        }

        function calculateToBePaid(employee) {
            employee.toBePaid = _round(employee.balance - employee.card + employee.bonuses);
        }

        function calculateTotal(employees) {

            var total = {
                salary: 0,
                card: 0,
                reimbursement: 0,
                overhead: 0,
                vacation: 0,
                bonuses: 0,
                penalty: 0,
                prepayment: 0,
                balance: 0,
                toBePaid: 0
            };

            _.forEach(employees, function (employee) {
                total.salary += employee.salary;
                total.card += employee.card;
                total.reimbursement += employee.reimbursement;
                total.vacation += employee.vacation;
                total.overhead += employee.overhead;
                total.bonuses += employee.bonuses;
                total.penalty += employee.penalty;
                total.prepayment += employee.prepayment;
                total.balance += employee.balance;
                total.toBePaid += employee.toBePaid;
            });

            $scope.total = total;
        }

        function _round(f) {
            return Math.ceil(100 * f) / 100.0;
        }

        getTransportRates(function () {
            getCurrencyRates(function () {
                getDepartments(function () {
                    getExpenseCategories(function () {
                        $scope.getSalaries(true);
                    });
                });
            });
        });

        $scope.onBankChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });
                $scope.transaction.account = null;
                $scope.transaction.account_id = null;
                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.bank.accounts.length == 1) {
                    $scope.transaction.account = $scope.transaction.bank.accounts[0];
                    $scope.transaction.account_id = $scope.transaction.bank.accounts[0].id;

                    if ($scope.transaction.account.payment_methods.length == 0) {
                        $scope.transaction.payment_method_id = null;
                    }

                    if ($scope.transaction.account.payment_methods.length == 1) {
                        $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == $scope.transaction.account_id;
                });

                $scope.transaction.payment_method_id = null;
                if ($scope.transaction.account && $scope.transaction.account.payment_methods.length == 1) {
                    $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                }
            }
        }

        $scope.onOneTimePaymentChange = function () {
            var desc = 'Аванс';
            $scope.transaction.source = 'avans';

            switch ($scope.transaction.onetime_payment) {
                case 'overtime':
                    desc = 'Overtime';
                    $scope.transaction.source = 'overtime';
                    break;
                case 'bonuses':
                    desc = 'Bonuses';
                    $scope.transaction.source = 'bonuses';
                    break;
            }

            $scope.transaction.description = desc;
        }

        $scope.showAddTransactionForm = function (employee, type) {
            $scope.transaction = {};

            if (employee.hasUpdate == 0) {
                $scope.transaction.hasUpdate = employee.hasUpdate;
                $scope.transaction.date = moment().format('MMMM, YYYY');
                $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');
                $scope.transaction.employee_id = employee.id;
                $scope.transaction.paid_to = employee.name;
                $scope.transaction.department_id = employee.department_id;
                $scope.transaction.expense_category_id = employee.expense_category_id;
                $scope.transaction.expense_category = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == employee.expense_category_id;
                });

                $scope.transaction.bank = employee.bank;
                $scope.transaction.bank_id = employee.bank_id;
                $scope.transaction.account = employee.account;
                $scope.transaction.account_id = employee.account_id;

                if (type == 'salary') {
                    if (typeof employee.salary == 'undefined') {
                        $scope.transaction.amount = 0;
                    } else {
                        $scope.transaction.amount = 1.0 * employee.toBePaid;
                    }

                    $scope.transaction.salary = (typeof employee.salary != 'undefined') ? employee.salary : 0;
                    $scope.transaction.card = (typeof employee.card != 'undefined') ? employee.card : 0;
                    $scope.transaction.prepayment = (typeof employee.prepayment != 'undefined') ? employee.prepayment : 0;
                    $scope.transaction.reimbursement = (typeof employee.reimbursement != 'undefined') ? employee.reimbursement : 0;
                    $scope.transaction.overhead = (typeof employee.overhead != 'undefined') ? employee.overhead : 0;
                    $scope.transaction.vacation = (typeof employee.vacation != 'undefined') ? employee.vacation : 0;
                    $scope.transaction.bonuses = (typeof employee.bonuses != 'undefined') ? employee.bonuses : 0;
                    $scope.transaction.penalty = (typeof employee.penalty != 'undefined') ? employee.penalty : 0;
                }

                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });

                if ($scope.transaction.bank) {
                    $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                        return account.id == $scope.transaction.account_id;
                    });
                }

                $scope.transaction.department = _.find($scope.departments, function (d) {
                    return d.id == $scope.transaction.department_id;
                });
            } else {
                var copyEmployee = angular.copy(employee);

                $scope.transaction = copyEmployee;

                if (typeof employee.salary == 'undefined') {
                    $scope.transaction.amount = 0;
                } else {
                    $scope.transaction.amount = 1.0 * copyEmployee.toBePaid;
                }

                $scope.transaction.salary = (typeof copyEmployee.salary != 'undefined') ? copyEmployee.salary : 0;
                $scope.transaction.card = (typeof copyEmployee.card != 'undefined') ? copyEmployee.card : 0;
                $scope.transaction.prepayment = (typeof copyEmployee.prepayment != 'undefined') ? copyEmployee.prepayment : 0;
                $scope.transaction.reimbursement = (typeof copyEmployee.reimbursement != 'undefined') ? copyEmployee.reimbursement : 0;
                $scope.transaction.overhead = (typeof copyEmployee.overhead != 'undefined') ? copyEmployee.overhead : 0;
                $scope.transaction.vacation = (typeof copyEmployee.vacation != 'undefined') ? copyEmployee.vacation : 0;
                $scope.transaction.bonuses = (typeof copyEmployee.bonuses != 'undefined') ? copyEmployee.bonuses : 0;
                $scope.transaction.penalty = (typeof copyEmployee.penalty != 'undefined') ? copyEmployee.penalty : 0;
                $scope.transaction.date = moment().format('MMMM, YYYY');
                $scope.transaction.transaction_date = moment($scope.transaction.transaction_date, 'YYYY-MM-DD').format('DD/MM/YYYY');

                if (typeof $scope.transaction.paid_to == 'undefined' || $scope.transaction.paid_to == '') $scope.transaction.paid_to = copyEmployee.name;
                $scope.transaction.expense_category = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == copyEmployee.expense_category_id;
                });
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == copyEmployee.bank_id;
                });

                if ($scope.transaction.bank) {
                    $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                        return account.id == copyEmployee.account_id;
                    });
                }

                $scope.transaction.department = _.find($scope.departments, function (d) {
                    return d.id == copyEmployee.department_id;
                });
            }

            $scope.transaction.type = 'expense';

            if (type == 'salary') {
                var items = [employee.salary + '$ base'];
                if (employee.card > 0) {
                    items.push(-employee.card + '$ to card');
                }
                if (employee.reimbursement > 0) {
                    items.push(employee.reimbursement + '$ transport compensation');
                }
                if (employee.overhead > 0) {
                    items.push(employee.overhead + '$ overtime');
                }
                if (employee.vacation > 0) {
                    items.push(employee.vacation + '$ vacation');
                }
                if (employee.bonuses > 0) {
                    items.push(employee.bonuses + '$ bonuses');
                }
                if (employee.penalty > 0) {
                    items.push(-employee.penalty + '$ penalty');
                }
                if (employee.prepayment > 0) {
                    items.push(-employee.prepayment + '$ avans');
                }

                $scope.transaction.description = employee.name + ' Salary (' + items.join(', ') + ')';
                $scope.transaction.source = 'salary';
            } else if (type == 'onetime_payment') {
                $scope.transaction.description = 'Аванс';
                $scope.transaction.onetime_payment = 'avans';
                $scope.transaction.source = 'avans';
            }

            $scope.$watch("transaction.reimbursement", function (newv, oldv, scope) {
                if (typeof newv == 'undefined') return;
                recalculate(newv, scope, 'reimbursement');
            });

            $scope.$watch("transaction.overhead", function (newv, oldv, scope) {
                if (typeof newv == 'undefined') return;
                recalculate(newv, scope, 'overhead');
            });

            $scope.$watch("transaction.vacation", function (newv, oldv, scope) {
                if (typeof newv == 'undefined') return;
                recalculate(newv, scope, 'vacation');
            });

            $scope.$watch("transaction.penalty", function (newv, oldv, scope) {
                if (typeof newv == 'undefined') return;
                recalculate(newv, scope, 'penalty');
            });

            $('#modal-default').modal('show');
        }

        function recalculate(newValue, scope, type) {
            if (type == 'reimbursement') scope.transaction.reimbursement = newValue;
            if (type == 'overhead') scope.transaction.overhead = newValue;
            if (type == 'vacation') scope.transaction.vacation = newValue;
            if (type == 'penalty') scope.transaction.penalty = newValue;

            scope.transaction.balance = _round(scope.transaction.salary + scope.transaction.reimbursement + scope.transaction.overhead + scope.transaction.vacation - scope.transaction.prepayment - scope.transaction.penalty);
            scope.transaction.amount = _round(scope.transaction.balance - scope.transaction.card + scope.transaction.bonuses);

            var items = [scope.transaction.salary + '$ base'];
            if (scope.transaction.card > 0) {
                items.push(-scope.transaction.card + '$ to card');
            }
            if (scope.transaction.reimbursement > 0) {
                items.push(scope.transaction.reimbursement + '$ transport compensation');
            }
            if (scope.transaction.overhead > 0) {
                items.push(scope.transaction.overhead + '$ overtime');
            }
            if (scope.transaction.vacation > 0) {
                items.push(scope.transaction.vacation + '$ vacation');
            }
            if (scope.transaction.bonuses > 0) {
                items.push(scope.transaction.bonuses + '$ bonuses');
            }
            if (scope.transaction.penalty > 0) {
                items.push(-scope.transaction.penalty + '$ penalty');
            }
            if (scope.transaction.prepayment > 0) {
                items.push(-scope.transaction.prepayment + '$ avans');
            }

            $scope.transaction.description = scope.transaction.paid_to + ' Salary (' + items.join(', ') + ')';
        }

        $scope.onTransactionDepartmentChanged = function () {
            if (!$scope.transaction) return;

            $scope.transaction.department = _.find($scope.departments, function (d) {
                return d.id == $scope.transaction.department_id;
            });
        }

        $scope.submitAddTransactionForm = function () {
            if (!$scope.transaction) return;

            if (!$scope.transaction.department_id) {
                error('Cost center not selected');
                return;
            }

            if (!$scope.transaction.expense_category_id) {
                error('Expense category not selected');
                return;
            }

            if ($scope.transaction.source == 'avans' || $scope.transaction.source == 'overtime' || $scope.transaction.source == 'bonuses') {
                if (!$scope.transaction.prepayment && !$scope.transaction.overhead && !$scope.transaction.bonuses) {
                    error('Amount not specified');
                    return;
                }

                $scope.transaction.amount = $scope.transaction.prepayment;

                switch ($scope.transaction.source) {
                    case 'overtime':
                        $scope.transaction.amount = $scope.transaction.overhead;
                        break;
                    case 'bonuses':
                        $scope.transaction.amount = $scope.transaction.bonuses;
                        break;
                }

            } else {
                if (!$scope.transaction.amount) {
                    error('Amount not specified');
                    return;
                }
            }

            if (!$scope.transaction.date) {
                error('Month not selected');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            if (!$scope.transaction.paid_to) {
                error('Paid to not specified');
                return;
            }

            if (!$scope.transaction.bank_id) {
                error('Bank is required');
                return;
            }

            if ($scope.transaction.bank.accounts && $scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account method is required');
                return;
            }

            if ($scope.transaction.account && $scope.transaction.account.payment_methods.length > 0 && !$scope.transaction.payment_method_id) {
                error('Payment method is required');
                return;
            }

            if (!$scope.transaction.description) {
                error('Description not specified');
                return;
            }

            var data = angular.copy($scope.transaction);
            data.date = moment($scope.transaction.date, 'MMMM, YYYY').format('YYYY-MM-DD');
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

            $scope.proccessing = true;

            if ($scope.transaction.source != 'avans' && $scope.transaction.source != 'overtime' && $scope.transaction.source != 'bonuses') {
                if ($scope.transaction.hasUpdate) {
                    $http.put(_env.apiUrl + '/salaries/' + $scope.transaction.hasUpdate, data).then(function (response) {
                        $scope.transaction.id = response.data.id;
                        $scope.getSalaries();
                        $('#modal-default').modal('hide');
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                } else {
                    $http.post(_env.apiUrl + '/salaries', data).then(function (response) {
                        $scope.transaction.id = response.data.id;
                        $scope.getSalaries();
                        $('#modal-default').modal('hide');
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            } else {
                $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                    $scope.transaction.id = response.data.id;
                    $scope.getSalaries();
                    $('#modal-default').modal('hide');
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.submitAcceptSalary = function (employee, type) {
            var copyEmployee = angular.copy(employee);

            $scope.transaction = copyEmployee;
            $scope.transaction.type = 'expense';
            $scope.transaction.amount = 1.0 * employee.toBePaid;
            $scope.transaction.date = moment($scope.transaction.date, 'YYYY-MM-DD').format('MMMM, YYYY');
            $scope.transaction.transaction_date = moment($scope.transaction.transaction_date, 'YYYY-MM-DD').format('DD/MM/YYYY');

            $scope.transaction.expense_category = _.find($scope.expenseCategories, function (expenseCategory) {
                return expenseCategory.id == employee.expense_category_id;
            });
            $scope.transaction.bank = _.find($scope.banks, function (bank) {
                return bank.id == employee.bank_id;
            });

            if ($scope.transaction.bank) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == employee.account_id;
                });
            }

            $scope.transaction.department = _.find($scope.departments, function (d) {
                return d.id == employee.department_id;
            });
            $scope.transaction.source = 'salary';

            $scope.transaction.accept_salary = 1;

            var data = angular.copy($scope.transaction);

            data.date = moment($scope.transaction.date, 'MMMM, YYYY').format('YYYY-MM-DD');
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

            if (employee.hasUpdate) {
                $http.put(_env.apiUrl + '/salaries/' + employee.hasUpdate, data).then(function (response) {
                    $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                        $scope.transaction.id = response.data.id;
                        $scope.getSalaries();
                        Swal.fire({
                            type: 'success',
                            title: 'Salary Accepted'
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.exportToCsv = function () {

            var headers = [
                'Department',
                'Employee',
                'Salary',
                'Transport',
                'Overheads',
                'Vacation',
                'Penalty',
                'Avans',
                'Total',
                'Card',
                'Bonuses',
                'To_Be_Paid'
            ];

            var objects = [];

            _.forEach($scope.employees, function (employee) {
                objects.push({
                    Department: employee.department.title,
                    Employee: employee.name,
                    Salary: employee.salary,
                    Transport: employee.reimbursement,
                    Overheads: employee.overhead,
                    Vacation: employee.vacation,
                    Penalty: employee.penalty,
                    Avans: employee.prepayment,
                    Total: employee.balance,
                    Card: employee.card,
                    Bonuses: employee.bonuses,
                    To_Be_Paid: employee.toBePaid
                });
            });

            var csvText = arr2csvText(headers, objects);
            downloadAsCSV('salaries_' + $scope.filters.period + '.csv', csvText)
        }

        $scope.showSalaryDetails = function (employee) {
            $scope.employee = employee;
            $('#modal-salary-details').modal('show');
        }

    }
})();