(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('BankAccountsCtrl', BankAccountsCtrl);

    function BankAccountsCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getPaymentMethods() {
            $http.get(_env.apiUrl + '/payment-methods?all').then(function (response) {
                $scope.paymentMethods = response.data;
            });
        }

        function getAccounts() {
            $http.get(_env.apiUrl + '/accounts?all').then(function (response) {
                $scope.accounts = response.data;
            });
        }

        function getBanks() {
            $http.get(_env.apiUrl + '/banks?all').then(function (response) {
                $scope.banks = response.data;
            });
        }

        getPaymentMethods();
        getAccounts();
        getBanks();

        $scope.showBankForm = function(bank) {
            if (bank) {
                $scope.editableBank = angular.copy(bank);
            } else {
                $scope.editableBank = {
                    status: 'active'
                }
            }

            $('#modal-bank').modal('show');
        }

        $scope.submitBankForm = function () {
            if (!$scope.editableBank) return;

            if (!$scope.editableBank.title) {
                error('Title is required');
                return;
            }

            $scope.proccessing = true;

            var data = {
                title: $scope.editableBank.title,
                type: $scope.editableBank.type,
                status: $scope.editableBank.status
            };

            if ($scope.editableBank.id) {
                $http.put(_env.apiUrl + '/banks/' + $scope.editableBank.id, data).then(function(response) {
                    $('#modal-bank').modal('hide');
                    getPaymentMethods();
                    getAccounts();
                    getBanks();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/banks', data).then(function(response) {
                    $('#modal-bank').modal('hide');
                    getBanks();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteBank = function (item) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/banks/' + item.id).then(function () {
                        getPaymentMethods();
                        getAccounts();
                        getBanks();
                        Swal.fire({
                            type: 'success',
                            title: 'Bank successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.showAccountForm = function(account) {
            $scope.editableAccount = {
                status: 'active'
            };

            if (account) {
                $scope.editableAccount = angular.copy(account);
            }

            $('#modal-account').modal('show');
        }

        $scope.submitAccountForm = function () {
            if (!$scope.editableAccount) return;

            if (!$scope.editableAccount.bank_id) {
                error('Bank is not selected');
                return;
            }

            if (!$scope.editableAccount.title) {
                error('Title is required');
                return;
            }

            if (!$scope.editableAccount.currency) {
                error('Currency is not selected');
                return;
            }

            if (!$scope.editableAccount.starting_balance) {
                $scope.editableAccount.starting_balance = 0;
            }

            $scope.proccessing = true;

            var data = {
                bank_id: $scope.editableAccount.bank_id,
                title: $scope.editableAccount.title,
                currency: $scope.editableAccount.currency,
                starting_balance: $scope.editableAccount.starting_balance,
                status: $scope.editableAccount.status
            };

            if ($scope.editableAccount.id) {
                $http.put(_env.apiUrl + '/accounts/' + $scope.editableAccount.id, data).then(function(response) {
                    $('#modal-account').modal('hide');
                    getPaymentMethods();
                    getAccounts();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/accounts', data).then(function(response) {
                    $('#modal-account').modal('hide');
                    getAccounts();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteAccount = function (item) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/accounts/' + item.id).then(function () {
                        getPaymentMethods();
                        getAccounts();
                        Swal.fire({
                            type: 'success',
                            title: 'Account successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.onPaymentMethodBankChanged = function() {
            if ($scope.editablePaymentMethod) {
                $scope.editablePaymentMethod.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editablePaymentMethod.bank_id;
                });
            }
        }

        $scope.showPaymentMethodForm = function(paymentMethod) {
            if (paymentMethod) {
                $scope.editablePaymentMethod = angular.copy(paymentMethod);
                $scope.editablePaymentMethod.bank_id = paymentMethod.account.bank_id;
                $scope.editablePaymentMethod.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editablePaymentMethod.bank_id;
                });
            } else {
                $scope.editablePaymentMethod = {
                    status: 'active'
                }
            }

            $('#modal-payment-method').modal('show');
        }

        $scope.submitPaymentMethodForm = function () {
            if (!$scope.editablePaymentMethod) return;

            if (!$scope.editablePaymentMethod.title) {
                error('Title is required');
                return;
            }

            if (!$scope.editablePaymentMethod.account_id) {
                error('Account is not selected');
                return;
            }

            if (!$scope.editablePaymentMethod.bank_id) {
                error('Bank is not selected');
                return;
            }

            $scope.proccessing = true;

            var data = {
                title: $scope.editablePaymentMethod.title,
                account_id: $scope.editablePaymentMethod.account_id,
                status: $scope.editablePaymentMethod.status
            };

            if ($scope.editablePaymentMethod.id) {
                $http.put(_env.apiUrl + '/payment-methods/' + $scope.editablePaymentMethod.id, data).then(function(response) {
                    $('#modal-payment-method').modal('hide');
                    getPaymentMethods();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/payment-methods', data).then(function(response) {
                    $('#modal-payment-method').modal('hide');
                    getPaymentMethods()
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deletePaymentMethod = function (item) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/payment-methods/' + item.id).then(function () {
                        getPaymentMethods();
                        Swal.fire({
                            type: 'success',
                            title: 'Payment Method successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.numeral = numeral;
    }
})();