(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('ReportsCtrl', ReportsCtrl);

    function ReportsCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        $scope.numeral = function (value, currency) {
            if (value > 0) {
                return '+' + currency + numeral(value).format('0,0.00');
            } else if (value < 0) {
                return '-' + currency + numeral(Math.abs(value)).format('0,0.00');
            }

            return currency + numeral(value).format('0,0.00');
        };
        $scope.moment = moment;

        var currencies = [
            'USD',
            'EUR',
            'AED',
            'UZS',
        ];

        function prepareMonths(year) {
            $scope.year = year;
            $scope.months = [];
            _.forEach([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (m) {
                $scope.months.push(moment().year(parseInt($scope.year)).startOf('year').add(m, 'month'))
            });
        }

        $scope.$watch('year', function(oldValue, newValue) {
            if (newValue == oldValue) return;

            prepareMonths($scope.year);

            getReportData(function () {
                getBalanceReportData();
            });
        });

        $scope.canAccessToTotalReport = function() {
            return AuthenticationService.user.can('manage-transactions') || AuthenticationService.user.can('manage-additional-actions');
        }

        function getCell(currency, value, float, bold, backgroundColor, paddingLeft, textColor, hrefData) {
            if(currency && hrefData)
                hrefData.currency = currency;

            var isNumberValue = typeof(value) !== 'string';
            var textColor = isNumberValue && value != 0 ? (value > 0 ? '#00a65a' : '#dd4b39') : 'inherit';
            return {
                currency: currency,
                value: value,
                float: float,
                bold: bold,
                backgroundColor: backgroundColor ? backgroundColor : '#fff;',
                paddingLeft: paddingLeft ? paddingLeft: 8,
                textColor: textColor,
                hrefData: hrefData,
                type: typeof(value)
            }
        }

        let visibles = {};
        $scope.isVisible = function (row) {
            if (row.departmentId && row.currency) {
                return visibles[row.departmentId + row.currency];
            }

            if (row.currency) {
                return visibles[row.currency];
            }

            return true;
        }

        function getReportData(callback) {
            $http.get(_env.apiUrl + '/transactions/report_data?year=' + $scope.year).then(function (response) {
                $scope.items = response.data;

                $scope.rows = [];

                _.forEach($scope.departments, function (department) {
                    let cells = [];

                    cells.push(getCell(null, department.title, 'left', true, '#f9f9f9;'));
                    _.forEach($scope.months, function (month) {
                        cells.push(getCell(null, month.format('MMM'), 'right', true, '#f9f9f9;'));
                    });
                    cells.push(getCell(null, 'Total', 'right', true, '#f9f9f9;'));
                    $scope.rows.push({
                        departmentId: department.id,
                        currency: null,
                        cells: cells
                    });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Revenue (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            let amount = getAmount(currency, month, department.id, 'income')
                            cells.push(getCell(currency, amount, 'right', false, null, null, '#00a65a', {
                                departmentId: department.id,
                                month: month.format('YYYY-MM-DD'),
                                type: 'income'
                            }));
                        });
                        let total = getAmount(currency, null, department.id, 'income');
                        cells.push(getCell(currency, total, 'right', true, null, null, '#00a65a'));
                        if(total != 0) {
                            visibles[department.id + currency] = true;
                            visibles[currency] = true;
                        }

                        $scope.rows.push({ cells: cells, currency: currency, departmentId: department.id });
                    });
                    $scope.rows.push({ isPaddingSmall: true });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Expense (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            let amount = -getAmount(currency, month, department.id, 'expense');
                            cells.push(getCell(currency, amount, 'right', false, null, null, '#dd4b39', {
                                departmentId: department.id,
                                month: month.format('YYYY-MM-DD'),
                                type: 'expense'
                            }));
                        });
                        let total = -getAmount(currency, null, department.id, 'expense');
                        cells.push(getCell(currency, total, 'right', true, null, null, '#dd4b39'));
                        if(total != 0) {
                            visibles[department.id + currency] = true;
                            visibles[currency] = true;
                        }
                        $scope.rows.push({ cells: cells, currency: currency, departmentId: department.id });
                    });
                    $scope.rows.push({ isPaddingSmall: true });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Profit (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            let amount = getAmount(currency, month, department.id, 'income') - getAmount(currency, month, department.id, 'expense');
                            cells.push(getCell(currency, amount, 'right', false));
                        });
                        var amount = getAmount(currency, null, department.id, 'income') - getAmount(currency, null, department.id, 'expense');
                        cells.push(getCell(currency, amount, 'right', true));
                        $scope.rows.push({ cells: cells, currency: currency, departmentId: department.id });
                    });

                    $scope.rows.push({
                        isPadding: true
                    });
                });

                if($scope.canAccessToTotalReport()) {
                    var cells = [];
                    cells.push(getCell(null, 'Total', 'left', true, '#f9f9f9;'));
                    _.forEach($scope.months, function (month) {
                        cells.push(getCell(null, month.format('MMM'), 'right', true, '#f9f9f9;'));
                    });
                    cells.push(getCell(null, 'Total', 'right', true, '#f9f9f9;'));
                    $scope.rows.push({ cells: cells });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Revenue (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            var amount = getAmount(currency, month, null, 'income');
                            cells.push(getCell(currency, amount, 'right', false, null, null, '#00a65a', {
                                departments: _.map($scope.departments, function(d) { return d.id; }).join(','),
                                month: month.format('YYYY-MM-DD'),
                                type: 'income'
                            }));
                        });
                        cells.push(getCell(currency, getAmount(currency, null, null, 'income'), 'right', true, null, null, '#00a65a'));
                        $scope.rows.push({ cells: cells, currency: currency });
                    });
                    $scope.rows.push({ isPaddingSmall: true });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Expense (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            var amount = -getAmount(currency, month, null, 'expense')
                            cells.push(getCell(currency, amount, 'right', false, null, null, '#dd4b39', {
                                departments: _.map($scope.departments, function(d) { return d.id; }).join(','),
                                month: month.format('YYYY-MM-DD'),
                                type: 'expense'
                            }));
                        });
                        cells.push(getCell(currency, -getAmount(currency, null, null, 'expense'), 'right', true, null, null, '#dd4b39'));
                        $scope.rows.push({ cells: cells, currency: currency });
                    });
                    $scope.rows.push({ isPaddingSmall: true });

                    _.forEach(currencies, function (currency) {
                        cells = [];
                        cells.push(getCell(null, 'Profit (' + currency + ')', 'left', false, null, 20));
                        _.forEach($scope.months, function (month) {
                            let revenue = getAmount(currency, month, null, 'income');
                            let expense = getAmount(currency, month, null, 'expense');
                            let amount = revenue - expense;
                            cells.push(getCell(currency, amount, 'right', false));
                        });
                        cells.push(getCell(currency, getAmount(currency, null, null, 'income') - getAmount(currency, null, null, 'expense'), 'right', true));
                        $scope.rows.push({ cells: cells, currency: currency });
                    });

                    $scope.rows.push({ isPadding: true });
                }


                if($scope.canAccessToTotalReport()) {
                    cells = [];
                    cells.push(getCell(null, 'Shareholders', 'left', true, '#f9f9f9;'));
                    _.forEach($scope.months, function (month) {
                        cells.push(getCell(null, month.format('MMM'), 'right', true, '#f9f9f9;'));
                    });
                    cells.push(getCell(null, 'Total', 'right', true, '#f9f9f9;'));
                    $scope.rows.push({ cells: cells });

                    _.forEach(currencies, function (currency) {
                        if(currency == 'USD') {
                            _.forEach($scope.shareholder.expense_categories, function (expense) {
                                var cells = [];
                                cells.push(getCell(null, expense.title + ' (' + currency + ')', 'left', false, null, 20));
                                _.forEach($scope.months, function (month) {
                                    var amount = getShareholderAmount(currency, month, expense.id);
                                    cells.push(getCell(currency, amount, 'right', false, null, null, '#dd4b39', {
                                        departments: $scope.shareholder.id,
                                        month: month.format('YYYY-MM-DD'),
                                        type: 'expense',
                                        expenseCategoryId: expense.id
                                    }));
                                });
                                cells.push(getCell(currency, getShareholderAmount(currency, null, expense.id), 'right', true, null, null, '#dd4b39'));
                                $scope.rows.push({ cells: cells });
                            });
                        }
                    });

                    _.forEach(currencies, function (currency) {
                        if(currency == 'USD') {
                            cells = [];
                            cells.push(getCell(currency, 'Total (' + currency + ')', 'left', true, null, 20));
                            _.forEach($scope.months, function (month) {
                                var amount = getShareholderAmount(currency, month, null);
                                cells.push(getCell(currency, amount, 'right', true));
                            });
                            cells.push(getCell(currency, getShareholderAmount(currency, null, null), 'right', true));
                            $scope.rows.push({ cells: cells });
                        }
                    });

                    $scope.rows.push({isPadding: true});
                }

                if (callback) callback();
            });
        }

        function getBalanceReportData(callback) {
            if(!$scope.canAccessToTotalReport()) {
                return;
            }

            $http.get(_env.apiUrl + '/transactions/balance_report_data').then(function (response) {
                $scope.balances = _.filter(_.map(_.groupBy(response.data.accounts, 'currency'), function (accounts, currency) {

                    var balance = {
                        currency: currency,
                        items: []
                    };

                    var items = [];

                    var accountIds = _.map(accounts, function (account) {
                        return account.id;
                    });

                    var transactionsByAccounts = _.map(_.filter(response.data.transactions, function (transaction) {
                        return accountIds.indexOf(transaction.account_id) > -1;
                    }), function (transaction) {
                        transaction.date = moment(transaction.date).startOf('month').format('YYYY-MM-DD');
                        return transaction;
                    });
                    balance.isEmpty = transactionsByAccounts.length == 0;

                    _.forEach(_.groupBy(_.filter(transactionsByAccounts, function (transaction) {
                        return transaction.type == 'income';
                    }), 'date'), function (transactions, date) {

                        if(!items[date]) {
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }

                        items[date].incomes = sum(transactions, 'amount');
                    });

                    _.forEach(_.groupBy(_.filter(transactionsByAccounts, function (transaction) {
                        return transaction.type == 'incoming_transfer';
                    }), 'date'), function (transactions, date) {

                        if(!items[date]) {
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }

                        items[date].incoming_transfer = sum(transactions, 'amount');
                    });

                    _.forEach(_.groupBy(_.filter(transactionsByAccounts, function (transaction) {
                        return (transaction.type == 'expense' && transaction.department_id != $scope.shareholder.id);
                    }), 'date'), function (transactions, date) {

                        if(!items[date]) {
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }

                        items[date].expenses = sum(transactions, 'amount');
                    });

                    _.forEach(_.groupBy(_.filter(transactionsByAccounts, function (transaction) {
                        return transaction.type == 'outgoing_transfer';
                    }), 'date'), function (transactions, date) {

                        if(!items[date]) {
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }

                        items[date].outgoing_transfer = sum(transactions, 'amount');
                    });

                    _.forEach(_.groupBy(_.filter(transactionsByAccounts, function (transaction) {
                        return transaction.department_id == $scope.shareholder.id;
                    }), 'date'), function (transactions, date) {

                        if(!items[date]) {
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }

                        items[date].dividends = sum(transactions, 'amount');
                    });


                    // fill not existes months
                    _.forEach($scope.months, function (month) {
                        var item = _.find(_.values(items), function (item) {
                            return item.date == month.format('YYYY-MM-DD');
                        });
                        if(!item) {
                            var date = month.format('YYYY-MM-DD');
                            items[date] = {
                                date: date,
                                prev_balance: 0,
                                expenses: 0,
                                incomes: 0,
                                outgoing_transfer: 0,
                                incoming_transfer: 0,
                                dividends: 0,
                                balance: 0
                            };
                        }
                    });

                    balance.items = [];

                    var startingBalance = sum(accounts, 'starting_balance');
                    var sortedItems = _.sortBy(_.values(items), 'date');
                    _.forEach(sortedItems, function (item) {
                        if(item.date <= moment().format('YYYY-MM-01')) {
                            item.prev_balance = startingBalance;
                            item.balance = startingBalance;
                            item.balance += item.incomes;
                            item.balance -= item.expenses;
                            item.balance += item.incoming_transfer;
                            item.balance -= item.outgoing_transfer;
                            item.balance -= item.dividends;
                            startingBalance = item.balance;
                        }

                        balance.items.push(item);
                    });

                    // filter by selected year
                    let startDate = $scope.year + '-01-01';
                    let endDate = $scope.year + '-12-31';
                    balance.items = _.filter(balance.items, function (item) {
                       return item.date >= startDate && item.date <= endDate;
                    });

                    balance.totalIncomes = sum(_.map(balance.items, function (item) {
                        return item.incomes;
                    }));

                    balance.totalExpenses = sum(_.map(balance.items, function (item) {
                        return item.expenses;
                    }));

                    balance.totalDividends = sum(_.map(balance.items, function (item) {
                        return item.dividends;
                    }));

                    return balance;
                }), function (balance) {
                    return !balance.isEmpty;
                });


                let cells = [];
                cells.push(getCell(null, 'Balance', 'left', true, '#f9f9f9;'));
                _.forEach($scope.months, function (month) {
                    cells.push(getCell(null, month.format('MMM'), 'right', true, '#f9f9f9;'));
                });
                cells.push(getCell(null, 'Total', 'right', true, '#f9f9f9;'));
                $scope.rows.push({ cells: cells });

                cells = [];
                _.forEach($scope.balances, function (balance) {
                    cells = [];
                    cells.push(getCell(null, 'Previous (' + balance.currency + ')', 'left', false, null, 20));
                    _.forEach(balance.items, function (item) {
                        cells.push(getCell(balance.currency, item.prev_balance, 'right'));
                    });
                    cells.push(getCell(null, 'N/A', 'right', true, null, null, '#dd4b39'));
                    $scope.rows.push({ cells: cells });
                });
                $scope.rows.push({ isPaddingSmall: true });
                _.forEach($scope.balances, function (balance) {
                    cells = [];
                    cells.push(getCell(balance.currency, 'Profit (' + balance.currency + ')', 'left', false, null, 20));
                    _.forEach(balance.items, function (item) {
                        var amount = item.incomes - item.expenses;
                        var color = amount > 0 ? '#00a65a' : '#dd4b39';
                        cells.push(getCell(balance.currency, amount, 'right', false, null, null, color));
                    });
                    let amount = balance.totalIncomes - balance.totalExpenses;
                    let color = amount > 0 ? '#00a65a' : '#dd4b39';
                    cells.push(getCell(balance.currency, amount, 'right', true, null, null, color));
                    $scope.rows.push({ cells: cells });
                });
                $scope.rows.push({ isPaddingSmall: true });
                _.forEach($scope.balances, function (balance) {
                    if (balance.currency == 'USD') {
                        cells = [];
                        cells.push(getCell(balance.currency, 'Shareholders (' + balance.currency + ')', 'left', false, null, 20));
                        _.forEach(balance.items, function (item) {
                            cells.push(getCell(balance.currency, -Math.abs(item.dividends), 'right', false, null, null, '#dd4b39'));
                        });
                        cells.push(getCell(balance.currency, -Math.abs(balance.totalDividends), 'right', true, null, null, '#dd4b39;'));
                        $scope.rows.push({ cells: cells });
                    }
                });
                $scope.rows.push({ isPaddingSmall: true });
                _.forEach($scope.balances, function (balance) {
                    cells = [];
                    cells.push(getCell(balance.currency, 'Current (' + balance.currency + ')', 'left', false, null, 20));
                    _.forEach(balance.items, function (item) {
                        cells.push(getCell(balance.currency, item.balance, 'right', false));
                    });
                    cells.push(getCell(balance.currency, 'N/A', 'right', true));
                    $scope.rows.push({ cells: cells });
                });
                $scope.rows.push({isPadding: true});

                if (callback) callback();
            });
        }

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = [];

                var apps = _.find(response.data, function (department) {
                    return department.id == 2;
                });
                if(apps) {
                    $scope.departments.push(apps);
                }

                var toys = _.find(response.data, function (department) {
                    return department.id == 3;
                });
                if(toys) {
                    $scope.departments.push(toys);
                }

                var cartoons = _.find(response.data, function (department) {
                    return department.id == 4;
                });
                if(cartoons) {
                    $scope.departments.push(cartoons);
                }

                var general = _.find(response.data, function (department) {
                    return department.id == 1;
                });
                if(general) {
                    $scope.departments.push(general);
                }

                var shareholder = _.find(response.data, function (department) {
                    return department.id == 6;
                });
                if(shareholder) {
                    $scope.shareholder = shareholder;
                } else {
                    $scope.shareholder = {
                        id: 6
                    };
                }

                if (callback) callback();
            });
        }

        prepareMonths(moment().format('YYYY'));

        getDepartments(function () {
            getReportData(function () {
                getBalanceReportData();
            });
        });

        function getAmount(currency, month, departmentId, type) {

            var amount = 0.0;
            _.forEach($scope.items, function (item) {
                if(
                    item.department_id != $scope.shareholder.id &&
                    (currency == item.currency) &&
                    (!month || month.format('YYYY-MM-DD') == item.date) &&
                    (!departmentId || departmentId == item.department_id) &&
                    (!type || type == item.type)) {
                    amount += item.amount;
                }
            });

            return amount;
        }

        function getShareholderAmount(currency, month, expenseCategoryId) {
            var amount = 0.0;
            _.forEach($scope.items, function (item) {
                if(
                    item.department_id == $scope.shareholder.id &&
                    item.currency == currency &&
                    (!month || month.format('YYYY-MM-DD') == item.date) &&
                    (!expenseCategoryId || expenseCategoryId == item.expense_category_id)) {
                    amount += item.amount;
                }
            });

            return -amount;
        }
    }
})();