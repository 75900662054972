(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('UsersCtrl', UsersCtrl);

    function UsersCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getUsers() {
            $http.get(_env.apiUrl + '/users').then(function (response) {
                $scope.users = _.map(response.data, function (user) {
                    if (user.permissions) user.permissions = user.permissions.split(",");
                    return user;
                });
            });
        }

        function getDepartments() {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;
            });
        }

        function getPositions() {
            $http.get(_env.apiUrl + '/positions').then(function (response) {
                $scope.positions = response.data;
            });
        }

        function getPermissions() {
            $http.get(_env.apiUrl + '/permissions').then(function (response) {
                $scope.permissions = response.data;
            });
        }

        getPermissions();
        getUsers();
        getDepartments();
        getPositions();

        $scope.editUser = function(user) {
            if (user) {
                $scope.editableUser = angular.copy(user);
            } else {
                $scope.editableUser = {}
            }

            $scope.editableUser.permissions = _.map($scope.permissions, function (permission) {
                var selected = user && user.permissions && user.permissions.indexOf(permission) != -1
                return {
                    title: permission,
                    selected: selected
                };
            })

            $('#modal-default').modal('show');
        }

        $scope.saveUser = function () {
            if (!$scope.editableUser) return;

            if (!$scope.editableUser.name) {
                return;
            }

            if (!$scope.editableUser.email) {
                return;
            }

            if (!$scope.editableUser.id && !$scope.editableUser.password) {
                return;
            }

            if (!$scope.editableUser.position_id) {
                return;
            }

            var data = {
                email: $scope.editableUser.email,
                name: $scope.editableUser.name,
                position_id: $scope.editableUser.position_id
            };

            if ($scope.editableUser.department_id) {
                data.department_id = $scope.editableUser.department_id;
            }

            if ($scope.editableUser.password) {
                data.password = $scope.editableUser.password;
            }

            var permissions = _.map(_.filter($scope.editableUser.permissions, function (p) {
                return p.selected;
            }), function (p) {
                return p.title;
            }).join(',');
            if (permissions) {
                data.permissions = permissions;
            }

            $scope.proccessing = true;

            if ($scope.editableUser.id) {
                $http.put(_env.apiUrl + '/users/update/' + $scope.editableUser.id, data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getUsers();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/users/add', data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getUsers();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteUser = function (user) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/users/' + user.id).then(function () {
                        getUsers();
                        Swal.fire({
                            type: 'success',
                            title: 'User Permissions successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.editDepartment = function (department) {
            var data = {
                title: department && department.title
            };
            Swal.fire({
                title: department ? 'Edit Cost Center' : 'Add Cost Center',
                input: 'text',
                inputValue: data.title,
                inputPlaceholder: 'Type category title here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some title!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    var data = {
                        'title': result.value.trim()
                    };

                    if(department) {
                        $http.put(_env.apiUrl + '/departments/' + department.id, data).then(function(response) {
                            getDepartments();
                            getUsers();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    } else {
                        $http.post(_env.apiUrl + '/departments', data).then(function(response) {
                            getDepartments();
                            getUsers();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    }
                }
            });
        }

        $scope.deleteDepartment = function (department) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/departments/' + department.id).then(function () {
                        getDepartments();
                        Swal.fire({
                            type: 'success',
                            title: 'Cost Center successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.editPosition = function (position) {
            var data = {
                title: position && position.title
            };
            Swal.fire({
                title: position ? 'Edit Position' : 'Add Position',
                input: 'text',
                inputValue: data.title,
                inputPlaceholder: 'Type category title here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some title!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    var data = {
                        'title': result.value.trim()
                    };

                    if (position) {
                        $http.put(_env.apiUrl + '/positions/' + position.id, data).then(function(response) {
                            getPositions();
                            getUsers();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    } else {
                        $http.post(_env.apiUrl + '/positions', data).then(function(response) {
                            getPositions();
                            getUsers();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    }
                }
            });
        }

        $scope.deletePosition = function (position) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/positions/' + position.id).then(function () {
                        getPositions();
                        Swal.fire({
                            type: 'success',
                            title: 'Position successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();