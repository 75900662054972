(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('ServicesCtrl', ServicesCtrl);

    function ServicesCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getBanks() {
            $http.get(_env.apiUrl + '/banks').then(function (response) {
                $scope.banks = response.data;
            });
        }

        function getServices() {
            $http.get(_env.apiUrl + '/services').then(function (response) {
                $scope.services = response.data;
            });
        }

        getBanks();
        getServices();

        $scope.onBankChanged = function() {
            if ($scope.editableService) {
                $scope.editableService.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editableService.bank_id;
                });
                if ($scope.editableService.bank.accounts.length == 0) {
                    $scope.editableService.account = null;
                    $scope.editableService.account_id = null;
                }
                if ($scope.editableService.bank.accounts.length == 1) {
                    $scope.editableService.account = $scope.editableService.bank.accounts[0];
                    $scope.editableService.account_id = $scope.editableService.bank.accounts[0].id;

                    if ($scope.editableService.account.payment_methods.length == 0) {
                        $scope.editableService.payment_method_id = null;
                    }

                    if ($scope.editableService.account.payment_methods.length == 1) {
                        $scope.editableService.payment_method_id = $scope.editableService.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function() {
            if ($scope.editableService) {
                $scope.editableService.account = _.find($scope.editableService.bank.accounts, function (account) {
                    return account.id == $scope.editableService.account_id;
                });

                $scope.editableService.payment_method_id = null;
                if ($scope.editableService.account.payment_methods.length == 1) {
                    $scope.editableService.payment_method_id = $scope.editableService.account.payment_methods[0].id;
                }
            }
        }

        $scope.showEditForm = function(service) {
            if (service) {
                $scope.editableService = angular.copy(service);
                $scope.editableService.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.editableService.bank_id;
                });
                $scope.editableService.account = _.find($scope.editableService.bank.accounts, function (account) {
                    return account.id == $scope.editableService.account_id;
                });
            } else {
                $scope.editableService = {}
            }

            $('#modal-default').modal('show');
        }

        $scope.submitEditForm = function () {
            if (!$scope.editableService) return;

            if (!$scope.editableService.title) {
                error('Title is required');
                return;
            }

            if (!$scope.editableService.comment) {
                error('Comment is required');
                return;
            }

            if (!$scope.editableService.description) {
                error('Description is required');
                return;
            }

            if (!$scope.editableService.bank_id) {
                error('Bank is required');
                return;
            }

            if ($scope.editableService.bank.accounts.length > 1 && !$scope.editableService.account_id) {
                error('Bank Account is required');
                return;
            }

            if ($scope.editableService.account && $scope.editableService.account.payment_methods && $scope.editableService.account.payment_methods.length > 1 && !$scope.editableService.payment_method_id) {
                error('Payment Method is required');
                return;
            }

            $scope.proccessing = true;

            var data = {
                title: $scope.editableService.title,
                description: $scope.editableService.description,
                comment: $scope.editableService.comment,
                bank_id: $scope.editableService.bank_id,
                account_id: $scope.editableService.account_id,
                payment_method_id: $scope.editableService.payment_method_id,
            };

            if ($scope.editableService.id) {
                $http.put(_env.apiUrl + '/services/' + $scope.editableService.id, data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getServices();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/services', data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getServices();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.delete = function (service) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/services/' + service.id).then(function () {
                        getServices();
                        Swal.fire({
                            type: 'success',
                            title: 'User successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();