(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('TransactionsBalanceCtrl', TransactionsBalanceCtrl);

    function TransactionsBalanceCtrl($scope, $state, $location, $http, AuthenticationService, _env, Uploader) {

        $scope.$watch('curTab', function(newValue, oldValue) {
            if(newValue == 'balance') {
                getBalance();
            }
        });

        $scope.end_date = moment().format('DD/MM/YYYY');
        $scope.$watch('end_date', function(newValue, oldValue) {
            getBalance();
        });

        function getBalance() {
            $http.get(_env.apiUrl + '/transactions/balances?end_date=' + moment($scope.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')).then(function (response) {
                var banks = response.data.banks;
                var accounts = response.data.accounts;
                var transactions = response.data.transactions;

                var items = [];
                _.forEach(accounts, function (account) {
                    var bank = _.find(banks, function (bank) {
                        return bank.id == account.bank_id;
                    });

                    if (bank) {
                        var item = {
                            account_id: account.id,
                            account: account.title,
                            bank_id: bank.id,
                            bank: bank.title,
                            amount: account.starting_balance,
                            currency: account.currency
                        };

                        _.forEach(transactions, function (transaction) {
                            if (transaction.bank_id == bank.id && transaction.account_id == account.id) {
                                if (transaction.type == 'income' || transaction.type == 'incoming_transfer') {
                                    item.amount += transaction.amount;
                                } else {
                                    item.amount -= transaction.amount;
                                }
                            }
                        });


                        items.push(item);
                    }
                });

                _.forEach(banks, function (bank) {
                   bank.accounts = _.filter(items, function (item) {
                       return item.bank_id == bank.id;
                   });
                });

                banks = _.filter(banks, function (bank) {
                   return bank.accounts && bank.accounts.length > 0;
                });

                var currencies = [
                    { id: 'USD', amount: 0 },
                    { id: 'EUR', amount: 0 },
                    { id: 'AED', amount: 0 },
                    { id: 'UZS', amount: 0 }
                ];

                _.forEach(currencies, function (currency) {
                    _.forEach(items, function (item) {
                        if (item.currency == currency.id) {
                            currency.amount += item.amount;
                        }
                    });
                });

                $scope.banks = banks;
                $scope.items = items;
                $scope.currencies = currencies;

            });
        }

        getBalance();

        $("#transactions-table").css("height", window.innerHeight - 100 + "px");
    }

})();