(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('ExpenseCategoriesCtrl', ExpenseCategoriesCtrl);

    function ExpenseCategoriesCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getExpenseCategories() {
            $http.get(_env.apiUrl + '/expense_categories').then(function (response) {
                $scope.expenseCategories = _.map(response.data, function (expenseCategory) {
                    expenseCategory.checkDepartment = function (departmentId) {
                        return _.find(expenseCategory.departments, function (department) {
                            return department.id == departmentId;
                        }) != null;
                    }
                    return expenseCategory;
                });
            });
        }

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;

                $scope.departmentsFilter = {
                    current: -1,
                    items: _.map($scope.departments, function (department) {
                        return {
                            id: department.id,
                            title: department.title
                        }
                    }),
                    onChange: function (current) {
                        $scope.departmentsFilter.current = current;
                    },
                    check: function (expenseCategory) {
                        return _.find(expenseCategory.departments, function (department) {
                            return department.id == $scope.departmentsFilter.current;
                        }) != null || $scope.departmentsFilter.current == -1;
                    }
                }

                if (callback) callback();
            });
        }

        getDepartments(function () {
            getExpenseCategories();
        });

        $scope.departmentsFilterChanged = function() {

        }

        $scope.showEditForm = function(editableExpenseCategory) {
            if (editableExpenseCategory) {
                $scope.editableExpenseCategory = angular.copy(editableExpenseCategory);
            } else {
                $scope.editableExpenseCategory = {}
            }

            $scope.editableExpenseCategory.departments = _.map($scope.departments, function (department) {
                var selected = $scope.editableExpenseCategory.departments && _.find($scope.editableExpenseCategory.departments, function(d) {
                    return d.id == department.id;
                }) != null;
                return {
                    id: department.id,
                    title: department.title,
                    selected: selected
                };
            })

            $('#modal-default').modal('show');
        }

        $scope.submitEditForm = function () {
            if (!$scope.editableExpenseCategory) {
                return;
            }

            if (!$scope.editableExpenseCategory.title) {
                return;
            }

            $scope.proccessing = true;
            var data = {
                title: $scope.editableExpenseCategory.title,
                description: $scope.editableExpenseCategory.description,
                departments: _.map(_.filter($scope.editableExpenseCategory.departments, function (department) {
                    return department.selected;
                }), function (department) {
                    return department.id;
                })
            };

            if ($scope.editableExpenseCategory.id) {
                $http.put(_env.apiUrl + '/expense_categories/' + $scope.editableExpenseCategory.id, data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getExpenseCategories();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/expense_categories', data).then(function(response) {
                    $('#modal-default').modal('hide');
                    getExpenseCategories();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.delete = function (expenseCategory) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/expense_categories/' + expenseCategory.id).then(function () {
                        getExpenseCategories();
                        Swal.fire({
                            type: 'success',
                            title: 'User successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();