(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('MonthlyReportCtrl', MonthlyReportCtrl);

    function MonthlyReportCtrl($scope, $state, $location, $stateParams, $http, AuthenticationService, _env) {

        $scope.month = $stateParams.month;
        $scope.departmentsParam = $stateParams.departments ? $stateParams.departments.split(",").join("|") : null;
        $scope.currency = $stateParams.currency;
        $scope.expenseCategoryId = $stateParams.expenseCategoryId;

        $scope.type = $stateParams.type;

        function getReportData() {
            $http
                .get(_env.apiUrl + '/transactions/monthly_report_data?'
                    + 'month=' + $scope.month
                    + '&type=' + $scope.type
                    + '&currency=' + $scope.currency
                    + '&departments=' + ($scope.departmentsParam ? $scope.departmentsParam : '')
                    + '&expenseCategoryId=' + ($scope.expenseCategoryId ? $scope.expenseCategoryId : '')
                )
                .then(function (response) {
                    $scope.totalAmount = 0;

                    if($scope.type == 'expense') {
                        var groups = [];

                        _.forEach(response.data, function (transaction) {
                            var key = 'cat_' + transaction.expense_category_id;
                            if (!groups[key]) {
                                groups[key] = {
                                    title: transaction.expense_category.title,
                                    transactions: [],
                                    amount: 0
                                }
                            }

                            groups[key].transactions.push(transaction);
                            groups[key].amount += transaction.amount;

                            $scope.totalAmount += transaction.amount;
                        });

                        $scope.groups = _.values(groups);
                    } else {
                        $scope.transactions = response.data;
                        _.forEach(response.data, function (transaction) {
                            $scope.totalAmount += transaction.amount;
                        });
                    }
                });
        }

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;


                if($scope.departmentsParam) {
                    $scope.department = _.find(response.data, function (department) {
                        return $scope.departmentsParam.split("|").indexOf('' + department.id) != -1;
                    });
                } else {
                    $scope.department = {
                        id: null,
                        title: ''
                    }
                }

                if (callback) callback();
            });
        }

        $scope.getAmount = function(expenseCategoryId) {
            var amount = 0.0;
            _.forEach($scope.items, function (item) {

                if(!expenseCategoryId || item.expense_category_id == expenseCategoryId) {
                    amount += item.amount;
                }
            });

            return amount;
        }

        $scope.getIncomeTotal = function() {
            var amount = 0.0;
            _.forEach($scope.items, function (item) {
                amount += item.amount;
            });

            return amount;
        }

        getDepartments(function () {
            getReportData()
        });

        $scope.numeral = numeral;
        $scope.moment = moment;
    }
})();