(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('ExpensesCtrl', ExpensesCtrl);

    function ExpensesCtrl($scope, $state, $location, $http, AuthenticationService, _env, Uploader) {
        $scope.numeral = numeral;

        $scope.curTab = 'expenses';

        $scope.filters = {
            month: getMonth(),
            department: 'all',
            onDepartmentFilterChanged: function () {
                getRequests();
            },
            expenseCategory: 'all',
            onExpenseCategoryFilterChanged: function () {
                getRequests();
            }
        };

        $scope.getExpenseCategories = function() {
            if (!$scope.filters || !$scope.filters.department) return null;

            var department = _.find($scope.departments, function (d) {
                return d.id == $scope.filters.department;
            });

            if (!department) return null;

            return department.expense_categories
        }

        $scope.canManageTransactions = function() {
            return AuthenticationService.user.can('manage-transactions');
        }

        $scope.canAdditionalActons = function() {
            return AuthenticationService.user.can('manage-additional-actions');
        }

        $scope.roleAccountant = function() {
            return AuthenticationService.user.can('role-accountant');
        }

        $scope.canManageRequests = function() {
            return AuthenticationService.user.can('manage-requests');
        }

        $scope.$watch("filters.month", function (newValue, oldValue) {
            if (newValue != oldValue) {
                setMonth($scope.filters.month);
                getRequests();
            }
        });

        function getDepartments(callback) {
            $http.get(_env.apiUrl + '/departments').then(function (response) {
                $scope.departments = response.data;

                if (callback) callback();
            });
        }

        function getExpenseCategories(callback) {
            $http.get(_env.apiUrl + '/expense_categories').then(function (response) {
                $scope.expenseCategories = response.data;

                if (callback) callback();
            });
        }

        function getBanks(callback) {
            $http.get(_env.apiUrl + '/banks').then(function (response) {
                $scope.banks = response.data;

                if (callback) callback();
            });
        }

        function getWhitelistedRequests(callback) {
            $http.get(_env.apiUrl + '/requests/whitelisted').then(function (response) {
                $scope.whitelistedRequests = response.data;

                if (callback) callback();
            });
        }

        function getData() {
            getExpenseCategories(function () {
                getDepartments(function () {
                    getBanks(function () {
                        getRequests();
                        getWhitelistedRequests();
                    });
                })
            });
        }

        getData();

        $scope.getCostCenterValue = function(request) {
            if (request.department) {
                return request.department.title;
            } else {
                return _.map(request.expense_category.departments, "title").join(", ");
            }
        }

        function getRequests(callback) {
            if (!$scope.filters.department) $scope.filters.department = 'all';
            if (!$scope.filters.expenseCategory) $scope.filters.expenseCategory = 'all';

            if ($scope.filters.department == 'all') {
                $scope.filters.expenseCategory = 'all';
            }

            var url = _env.apiUrl +
                '/requests?month=' +
                moment($scope.filters.month, 'MMMM, YYYY').format('YYYY-MM-DD') +
                ($scope.filters.department && $scope.filters.department != 'all' ? '&department=' + $scope.filters.department : '') +
                ($scope.filters.department && $scope.filters.expenseCategory != 'all' ? '&expense_category=' + $scope.filters.expenseCategory : '');

            $http.get(url).then(function (response) {
                $scope.requests = response.data;

                $scope.pendingRequests = {
                    items: [],
                    totalAmount: 0
                };
                $scope.acceptedRequests = {
                    items: [],
                    totalAmount: 0
                };
                $scope.declinedRequests = {
                    items: [],
                    totalAmount: 0
                };
                _.forEach($scope.requests, function (request) {
                    if (request.status == 'accepted') {
                        $scope.acceptedRequests.items.push(request);
                        $scope.acceptedRequests.totalAmount += request.amount;
                    } else if (request.status == 'declined') {
                        $scope.declinedRequests.items.push(request);
                        $scope.declinedRequests.totalAmount += request.amount;
                    } else {
                        $scope.pendingRequests.items.push(request);
                        $scope.pendingRequests.totalAmount += request.amount;
                    }
                });

                if (callback) callback();
            });
        }

        $scope.onBankChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });
                $scope.transaction.account = null;
                $scope.transaction.account_id = null;
                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.bank.accounts.length == 1) {
                    $scope.transaction.account = $scope.transaction.bank.accounts[0];
                    $scope.transaction.account_id = $scope.transaction.bank.accounts[0].id;

                    if ($scope.transaction.account.payment_methods.length == 0) {
                        $scope.transaction.payment_method_id = null;
                    }

                    if ($scope.transaction.account.payment_methods.length == 1) {
                        $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == $scope.transaction.account_id;
                });

                $scope.transaction.payment_method_id = null;
                if ($scope.transaction.account && $scope.transaction.account.payment_methods.length == 1) {
                    $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                }
            }
        }

        $scope.showAddTransactionForm = function(request, whitelisted) {
            $scope.transaction = {};

            $scope.transaction.type = 'expense';
            if(!request.department_id) {
                $scope.transaction.type = 'combined_expense';
            }
            $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');
            if(whitelisted) {
                $scope.transaction.date = moment().format('MMMM, YYYY');
            } else {
                $scope.transaction.request_id = request.id;
                $scope.transaction.date = moment(request.date).format('MMMM, YYYY');
            }
            $scope.transaction.department_id = request.department_id;
            $scope.transaction.department = request.department;
            $scope.transaction.expense_category_id = request.expense_category_id;
            $scope.transaction.expense_category = request.expense_category;
            $scope.transaction.description = request.description;
            $scope.transaction.payment_note = request.payment_note;

            $scope.transaction.addDepartment = function(newDepartment) {
                if(!$scope.transaction.departments) {
                    $scope.transaction.departments = [];
                }
                if(!_.find($scope.transaction.departments, function (department) {
                    return department.id == newDepartment.id;
                })) {
                    $scope.transaction.departments.push({
                        id: newDepartment.id,
                        title: newDepartment.title,
                        amount: 0,
                        expense_category_id: $scope.transaction.expense_category_id
                    });
                }
            }

            $scope.transaction.deleteDepartment = function(index) {
                if(!$scope.transaction.departments) {
                    $scope.transaction.departments = [];
                }

                $scope.transaction.departments.splice(index, 1);
            }

            $('#modal-default').modal('show');
        }

        $scope.submitAddTransactionForm = function () {
            if (!$scope.transaction) return;

            if (!$scope.transaction.department_id) {
                var hasNotSpecifiedAmount = _.find($scope.transaction.departments, function (department) {
                    return !department.amount;
                });
                if (hasNotSpecifiedAmount) {
                    error("Amount not specified");
                    return;
                }
            } else {
                if (!$scope.transaction.department_id) {
                    error('Cost center not selected');
                    return;
                }

                if (!$scope.transaction.expense_category_id) {
                    error('Expense category not selected');
                    return;
                }

                if (!$scope.transaction.amount) {
                    error('Amount not specified');
                    return;
                }

            }

            if (!$scope.transaction.paid_to) {
                error('Paid to not specified');
                return;
            }

            if (!$scope.transaction.bank_id) {
                error('Bank not selected');
                return;
            }

            if ($scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account not selected');
                return;
            }

            if ($scope.transaction.account && $scope.transaction.account.payment_methods.length > 0) {
                if (!$scope.transaction.payment_method_id) {
                    error('Payment method not selected');
                    return;
                }
            }

            if (!$scope.transaction.date) {
                error('Month not selected');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            if (!$scope.transaction.description) {
                error('Description not specified');
                return;
            }

            var data = angular.copy($scope.transaction);
            data.date = moment($scope.transaction.date, 'MMMM, YYYY').format('YYYY-MM-DD');
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

            $scope.proccessing = true;

            $http.post(_env.apiUrl + '/transactions', data).then(function(response) {
                $scope.transaction.id = (Array.isArray(response.data)) ? response.data[0]['id'] : response.data.id;

                if ($scope.transaction.invoice) {
                    uploadInvoice(function () {
                        getRequests();
                        $('#modal-default').modal('hide');
                    })
                } else {
                    getRequests();
                    $('#modal-default').modal('hide');
                }
            }, function (reason) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });
        }

        function uploadInvoice(callback) {
            if ($scope.transaction.invoice && $scope.transaction.invoice.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.transaction.invoice], _env.apiUrl + '/uploads?attachable_type=invoice&attachable_id=' + $scope.transaction.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function() {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.transaction.newInvoice = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        $scope.getInvoice = function () {
            if($scope.transaction && $scope.transaction.newInvoice) {
                return $scope.transaction.newInvoice.local_src;
            } else if ($scope.transaction && $scope.transaction.invoice) {
                return $scope.transaction.invoice.file_url;
            } else {
                return 'img/profile.png';
            }
        }

        $scope.showExpenseForm = function(request) {
            if(request) {
                $scope.request = angular.copy(request);
                $scope.request.date = moment($scope.request.date).format('MMMM, YYYY');
                $scope.request.expenseCategory = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == $scope.request.expense_category_id;
                });
                $scope.request.department_id = $scope.request.department_id;
                if ($scope.request.department_id) {
                    $scope.request.department = _.find($scope.request.expenseCategory.departments, function (department) {
                        return department.id == $scope.request.department_id;
                    });
                }
            } else {
                $scope.request = {};
            }

            $scope.request.onExpenseCategoryChanged = function() {
                $scope.request.expenseCategory = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == $scope.request.expense_category_id;
                });

                if ($scope.request.expenseCategory.departments.length == 1) {
                    $scope.request.department = $scope.request.expenseCategory.departments[0];
                    $scope.request.department_id = $scope.request.expenseCategory.departments[0].id;
                }
            }

            $('#modal-expense').modal('show');
        }

        $scope.submitExpenseForm = function () {
            if (!$scope.request) return;

            if (!$scope.request.date) {
                error('Month not selected');
                return;
            }

            if (!$scope.request.expense_category_id) {
                error('Expense category not selected');
                return;
            }

            if (!$scope.request.department_id) {
                error('Cost center not selected');
                return;
            }

            if (!$scope.request.description) {
                error('Description not specified');
                return;
            }

            if (!$scope.request.amount) {
                error('Amount not specified');
                return;
            }

            if (!$scope.request.payment_note) {
                error('Payment details not specified');
                return;
            }

            var data = {
                request_id: $scope.request.id,
                date: moment($scope.request.date, 'MMMM, YYYY').format('YYYY-MM-DD'),
                amount: $scope.request.amount,
                payment_note: $scope.request.payment_note,
                department_id: $scope.request.department_id,
                expense_category_id: $scope.request.expense_category_id,
                description: $scope.request.description
            }

            if ($scope.request.status == 'declined') {
                data.status = 'declined';
                data.decline_reason = $scope.request.decline_reason;
            } else if ($scope.request.status == 'accepted') {
                data.status = 'accepted';
            }

            $scope.proccessing = true;

            if ($scope.request.id) {
                $http.put(_env.apiUrl + '/requests/' + $scope.request.id, data).then(function() {
                    $('#modal-expense').modal('hide');
                    getRequests();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/requests', data).then(function() {
                    $('#modal-expense').modal('hide');
                    getRequests();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.accept = function () {
            Swal.fire({
                title: 'Are you sure?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, accept it!',
                reverseButtons: true
            }).then(function (result) {
                if (result.value) {
                    $scope.request.status = 'accepted';
                    $scope.submitExpenseForm();
                }
            });
        }

        $scope.decline = function () {
            Swal.fire({
                title: 'Decline',
                input: 'text',
                inputPlaceholder: 'Type decline reason here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some reason!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    $scope.request.status = 'declined';
                    $scope.request.decline_reason = result.value.trim();
                    $scope.submitExpenseForm();
                }
            });
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/requests/' + $scope.request.id).then(function () {
                        $('#modal-expense').modal('hide');
                        getRequests();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }



        // Whitelisted requests

        $scope.showWhitelistedExpense = function(request) {
            $scope.request = {
                type: 'single',
                items: []
            };

            if (request) {
                $scope.request.id = request.id;
                $scope.request.type = request.type;
                $scope.request.items = request.items;
                $scope.request.expense_category_id = request.expense_category_id;
                $scope.request.expenseCategory = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == request.expense_category_id;
                });
                $scope.request.department_id = request.department_id;
                $scope.request.description = request.description;
            }

            $scope.request.onExpenseCategoryChanged = function() {
                $scope.request.expenseCategory = _.find($scope.expenseCategories, function (expenseCategory) {
                    return expenseCategory.id == $scope.request.expense_category_id;
                });
            }

            $('#modal-whitelisted-expense').modal('show');
        }

        $scope.submitWhitelistedExpense = function () {
            if (!$scope.request) return;

            if (!$scope.request.expense_category_id) {
                error('Expense category not selected');
                return;
            }

            if (!$scope.request.description) {
                error('Description not specified');
                return;
            }

            var data = angular.copy($scope.request);

            $scope.proccessing = true;

            if ($scope.request.id) {
                $http.put(_env.apiUrl + '/requests/whitelisted/' + $scope.request.id, data).then(function() {
                    $('#modal-whitelisted-expense').modal('hide');
                    getWhitelistedRequests();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/requests/whitelisted', data).then(function() {
                    $('#modal-whitelisted-expense').modal('hide');
                    getWhitelistedRequests();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteWhitelistedExpense = function () {
            if (!$scope.request || !$scope.request.id) return;

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/requests/whitelisted/' + $scope.request.id).then(function () {
                        $('#modal-whitelisted-expense').modal('hide');
                        getWhitelistedRequests();

                        Swal.fire({
                            type: 'success',
                            title: 'Data successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();