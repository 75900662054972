(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('HomeCtrl', HomeCtrl);

    function HomeCtrl($scope, $location, $http, AuthenticationService, _env) {

        $scope.auth = AuthenticationService;
        $scope.end_date = moment().format('DD/MM/YYYY');

        AuthenticationService.refresh(function () {
            $scope.user = AuthenticationService.user;
            $('[data-widget="tree"]').tree();
        });

        $scope.logout = function () {
            AuthenticationService.Logout();
        };

        $scope.showChangePasswordForm = function () {
            Swal.fire({
                title: 'Change password',
                input: 'password',
                inputPlaceholder: 'Type new password here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some password!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    var data = {
                        'password': result.value.trim()
                    };

                    $http.put(_env.apiUrl + '/users/change_password', data).then(function(response) {
                        Swal.fire({
                            type: 'success',
                            title: 'Password successfully saved!'
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        };

        $scope.getCurrentYear = function() {
            return new Date().getFullYear();
        }

        function getBalance() {
            $http.get(_env.apiUrl + '/transactions/balances?end_date=' + moment($scope.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')).then(function (response) {
                var banks = response.data.banks;
                var accounts = response.data.accounts;
                var transactions = response.data.transactions;

                _.forEach(accounts, function (account) {
                    var bank = _.find(banks, function (bank) {
                        return bank.id == account.bank_id;
                    });

                    if (bank && account.id == 5) {

                        $scope.balance_amount = account.starting_balance;
                        $scope.balance_currency = account.currency;

                        _.forEach(transactions, function (transaction) {
                            if (transaction.bank_id == bank.id && transaction.account_id == account.id) {
                                if (transaction.type == 'income' || transaction.type == 'incoming_transfer') {
                                    $scope.balance_amount += transaction.amount;
                                } else {
                                    $scope.balance_amount -= transaction.amount;
                                }
                            }
                        });
                    }
                });
            });
        }

        getBalance();

        $scope.numeral = numeral;
    }
})();