(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('TransactionsTransitsCtrl', TransactionsTransitsCtrl)
        .controller('TransactionsLoansCtrl', TransactionsLoansCtrl);

    function TransactionsTransitsCtrl($scope, $state, $location, $http, AuthenticationService, _env, Uploader) {

        function getTransactions() {
            $http.get(_env.apiUrl + '/transactions/transits').then(function (response) {
                $scope.transactions = _.map(response.data, function (transaction) {
                    var amount = parseInt(transaction.amount * 100);
                    _.forEach(transaction.transits, function (t) {
                        amount -= parseInt(t.amount * 100);
                    });
                    transaction.balance = amount / 100.0;
                    return transaction;
                });
            });
        }

        $scope.$watch('curTab', function(newValue, oldValue) {
            if(newValue == 'transits') {
                getTransactions();
            }
        });

        $scope.onBankChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });
                $scope.transaction.account = null;
                $scope.transaction.account_id = null;
                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.bank.accounts.length == 1) {
                    $scope.transaction.account = $scope.transaction.bank.accounts[0];
                    $scope.transaction.account_id = $scope.transaction.bank.accounts[0].id;

                    if ($scope.transaction.account.payment_methods.length == 1) {
                        $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == $scope.transaction.account_id;
                });

                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.account && $scope.transaction.account.payment_methods.length == 1) {
                    $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                }
            }
        }

        $scope.onTransactionDepartmentChanged = function() {
            if (!$scope.transaction) return;

            $scope.transaction.department = _.find($scope.departments, function (d) {
                return d.id == $scope.transaction.department_id;
            });
        }

        $scope.showTransactionForm = function(transaction) {
            $scope.transaction = {
                type: 'outgoing_transfer',
                transit_id: transaction.id
            };
            $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');

            $('#modal-transfer-incoming').modal('show');
        }

        $scope.submitTransactionForm = function () {
            if (!$scope.transaction) return;

            if ($scope.transaction.type == 'expense' || $scope.transaction.type == 'income') {
                if (!$scope.transaction.department_id) {
                    error('Cost center not selected');
                    return;
                }

                if ($scope.transaction.type == 'expense' && !$scope.transaction.expense_category_id) {
                    error('Expense category not selected');
                    return;
                }
            }

            if (!$scope.transaction.bank_id) {
                error('Bank not selected');
                return;
            }

            if ($scope.transaction.bank.accounts && $scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account not selected');
                return;
            }

            if ($scope.transaction.account && $scope.transaction.account.payment_methods.length > 0) {
                if (!$scope.transaction.payment_method_id && $scope.transaction.type == 'expense') {
                    error('Payment method not selected');
                    return;
                }
            }

            if (!$scope.transaction.amount) {
                error('Amount not specified');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            if (!$scope.transaction.description) {
                error('Details not specified');
                return;
            }

            var data = angular.copy($scope.transaction);
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM') + '-01';

            $scope.proccessing = true;
            $http.post(_env.apiUrl + '/transactions', data).then(function(response) {
                $scope.transaction.id = response.data.id;
                if ($scope.transaction.invoice) {
                    uploadInvoice(function () {
                        getTransactions();
                        $('#modal-transfer-incoming').modal('hide');
                    })
                } else {
                    getTransactions();
                    $('#modal-transfer-incoming').modal('hide');
                }
            }, function (reason) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });
        }

        function uploadInvoice(callback) {
            if ($scope.transaction.invoice && $scope.transaction.invoice.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.transaction.invoice], _env.apiUrl + '/uploads?attachable_type=invoice&attachable_id=' + $scope.transaction.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function() {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.transaction.newInvoice = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        getTransactions();
    }

    function TransactionsLoansCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getTransactions() {
            $http.get(_env.apiUrl + '/transactions/loans').then(function (response) {
                $scope.transactions = _.map(response.data, function (transaction) {
                    var amount = parseInt(transaction.amount * 100);
                    _.forEach(transaction.transits, function (t) {
                        amount -= parseInt(t.amount * 100);
                    });
                    transaction.balance = amount / 100.0;

                    return transaction;
                });
            });
        }

        $scope.$watch('curTab', function(newValue, oldValue) {
            if(newValue == 'loans') {
                getTransactions();
            }
        });

        $scope.onBankChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });
                $scope.transaction.account = null;
                $scope.transaction.account_id = null;
                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.bank.accounts.length == 1) {
                    $scope.transaction.account = $scope.transaction.bank.accounts[0];
                    $scope.transaction.account_id = $scope.transaction.bank.accounts[0].id;

                    if ($scope.transaction.account.payment_methods.length == 1) {
                        $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function() {
            if ($scope.transaction) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == $scope.transaction.account_id;
                });

                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.account && $scope.transaction.account.payment_methods.length == 1) {
                    $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                }
            }
        }

        $scope.onTransactionDepartmentChanged = function() {
            if (!$scope.transaction) return;

            $scope.transaction.department = _.find($scope.departments, function (d) {
                return d.id == $scope.transaction.department_id;
            });
        }

        $scope.showTransactionForm = function(transaction) {
            $scope.transaction = {
                type: 'outgoing_transfer',
                transit_id: transaction.id
            };
            $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');

            $('#modal-loan-incoming').modal('show');
        }

        $scope.submitTransactionForm = function () {
            if (!$scope.transaction) return;

            if ($scope.transaction.type == 'expense' || $scope.transaction.type == 'income') {
                if (!$scope.transaction.department_id) {
                    error('Cost center not selected');
                    return;
                }

                if ($scope.transaction.type == 'expense' && !$scope.transaction.expense_category_id) {
                    error('Expense category not selected');
                    return;
                }
            }

            if (!$scope.transaction.bank_id) {
                error('Bank not selected');
                return;
            }

            if ($scope.transaction.bank.accounts && $scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account not selected');
                return;
            }

            if ($scope.transaction.account && $scope.transaction.account.payment_methods.length > 0) {
                if (!$scope.transaction.payment_method_id && $scope.transaction.type == 'expense') {
                    error('Payment method not selected');
                    return;
                }
            }

            if (!$scope.transaction.amount) {
                error('Amount not specified');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            var data = angular.copy($scope.transaction);
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM') + '-01';

            $scope.proccessing = true;
            $http.post(_env.apiUrl + '/transactions', data).then(function(response) {
                $scope.transaction.id = response.data.id;
                if ($scope.transaction.invoice) {
                    uploadInvoice(function () {
                        getTransactions();
                        $('#modal-loan-incoming').modal('hide');
                    })
                } else {
                    getTransactions();
                    $('#modal-loan-incoming').modal('hide');
                }
            }, function (reason) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });
        }

        function uploadInvoice(callback) {
            if ($scope.transaction.invoice && $scope.transaction.invoice.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.transaction.invoice], _env.apiUrl + '/uploads?attachable_type=invoice&attachable_id=' + $scope.transaction.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function() {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.transaction.newInvoice = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        getTransactions();

    }

})();