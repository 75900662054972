(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .directive('datePicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        format: 'dd/mm/yyyy',
                        autoclose: true,
                        startView: 'days'
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        })
        .directive('yearPicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        autoclose: true,
                        startView: 'decade',
                        format: "yyyy",
                        viewMode: "years",
                        minViewMode: "years"
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        })
        .directive('monthPicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        autoclose: true,
                        startView: 'months',
                        format: 'MM, yyyy',
                        viewMode: "months",
                        minViewMode: "months"
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        })
        .directive('dateRangePicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    function cb(start, end) {
                        if (!start || !end) return;

                        var el = $('span', element);
                        el.html(start.format('DD MMM, YYYY') + ' - ' + end.format('DD MMM, YYYY'));
                    }

                    var ranges  = $scope.dateFilter.ranges;
                    if (!ranges) {
                        ranges = {
                            'Last 30 Days': [moment().subtract(29, 'days'), moment().add(1, 'days')],
                            'All Time': [moment('2019-12-01'), moment().add(1, 'days')]
                        };
                    }
                    element.daterangepicker({
                        ranges: ranges,
                        startDate: $scope.dateFilter.start,
                        endDate: $scope.dateFilter.end,
                        locale: {
                            format: 'DD/MM/YYYY'
                        }
                    }, function (start, end) {
                        $scope.dateFilter.start = start;
                        $scope.dateFilter.end = end;
                        cb(start, end);
                        $scope.dateFilter.callback();
                    });

                    cb($scope.dateFilter.start, $scope.dateFilter.end);
                }
            };
        });

})();