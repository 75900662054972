(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .directive('myTable', function ($parse) {
            return {
                restrict: 'E, A, C',
                link: function (scope, element, attrs) {
                    scope.table = element.dataTable(scope.options);


                    element.on('click', 'tr', function () {
                        if (scope.options.onItemClick) {
                            var rowData = scope.table.api().row($(this)).data();
                            scope.options.onItemClick(rowData);
                        };
                    } );
                },
                scope: {
                    options: "="
                }
            };
        });

})();