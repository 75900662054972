(function () {
    'use strict';

    angular
        .module('budget.bimiboo.com')
        .controller('TransactionsListCtrl', TransactionsListCtrl);

    function TransactionsListCtrl($scope, $state, $location, $http, AuthenticationService, _env, Uploader) {

        $scope.filters = {
            department: 'all',
            onDepartmentFilterChanged: function () {
                getTransactions();
            },
            expenseCategory: 'all',
            onExpenseCategoryFilterChanged: function () {
                getTransactions();
            },
            bank_id: 'all',
            banks: null,
            onBankChanged: function () {
                $scope.filters.accounts = null;
                var bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.filters.bank_id;
                });
                if (bank) {
                    $scope.filters.accounts = bank.accounts;
                    $scope.filters.account_id = null;
                    $scope.filters.payment_method_id = null;
                }
                getTransactions();
            },
            account_id: 'all',
            accounts: null,
            onAccountChanged: function () {
                var account = _.find($scope.filters.accounts, function (account) {
                    return account.id == $scope.filters.account_id;
                });
                if (account) {
                    $scope.filters.payment_methods = account.payment_methods;
                    $scope.filters.payment_method_id = null;
                }
                getTransactions();
            },
            payment_method_id: 'all',
            payment_methods: null,
            onPaymentMethodChanged: function () {
                getTransactions();
            }
        };

        // date control
        var dateRange = getDateRage();
        $scope.dateFilter = {
            start: dateRange.start,
            end: dateRange.end,
            callback: function () {
                setDateRage({
                    start: $scope.dateFilter.start,
                    end: $scope.dateFilter.end
                });
                getTransactions();
            }
        };

        $scope.sortable = "-transaction_date";

        function getExpenseCategories() {
            $http.get(_env.apiUrl + '/expense_categories').then(function (response) {
                $scope.expense_categories = response.data;
            });
        }

        $scope.getExpenseCategories = function () {
            if (!$scope.filters || !$scope.filters.department) return null;

            var department = _.find($scope.departments, function (d) {
                return d.id == $scope.filters.department;
            });

            if (!department) return null;

            return department.expense_categories
        }

        function getTransactions(callback) {
            if (!$scope.filters.department) $scope.filters.department = 'all';
            if (!$scope.filters.expenseCategory) $scope.filters.expenseCategory = 'all';

            if ($scope.filters.department == 'all') {
                $scope.filters.expenseCategory = 'all';
            }

            $http.get(
                _env.apiUrl +
                '/transactions' +
                '?start_date=' + $scope.dateFilter.start.format('YYYY-MM-DD') + '&end_date=' + $scope.dateFilter.end.format('YYYY-MM-DD') +
                ($scope.filters.department && $scope.filters.department != 'all' ? '&department=' + $scope.filters.department : '') +
                ($scope.filters.expenseCategory && $scope.filters.expenseCategory != 'all' ? '&expense_category=' + $scope.filters.expenseCategory : '') +
                ($scope.filters.bank_id && $scope.filters.bank_id != 'all' ? '&bank_id=' + $scope.filters.bank_id : '') +
                ($scope.filters.account_id && $scope.filters.account_id != 'all' ? '&account_id=' + $scope.filters.account_id : '') +
                ($scope.filters.payment_method_id && $scope.filters.payment_method_id != 'all' ? '&payment_method_id=' + $scope.filters.payment_method_id : '')
            ).then(function (response) {
                $scope.transactions = response.data;
                $scope.totalAmount = 0;

                _.forEach($scope.transactions, function (r) {
                    $scope.totalAmount += r.amount;
                });

                if (callback) callback();
            });
        }

        getTransactions();
        getExpenseCategories();

        $scope.onBankChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });
                $scope.transaction.account = null;
                $scope.transaction.account_id = null;
                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.bank.accounts.length == 1) {
                    $scope.transaction.account = $scope.transaction.bank.accounts[0];
                    $scope.transaction.account_id = $scope.transaction.bank.accounts[0].id;

                    if ($scope.transaction.account.payment_methods.length == 1) {
                        $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onAccountChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                    return account.id == $scope.transaction.account_id;
                });

                $scope.transaction.payment_method_id = null;

                if ($scope.transaction.account && $scope.transaction.account.payment_methods.length == 1) {
                    $scope.transaction.payment_method_id = $scope.transaction.account.payment_methods[0].id;
                }
            }
        }

        $scope.onIncomeBankChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.incoming_type = "incoming_transfer";

                $scope.transaction.income_bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.income_bank_id;
                });
                $scope.transaction.income_account = null;
                $scope.transaction.income_account_id = null;
                $scope.transaction.income_payment_method_id = null;

                if ($scope.transaction.income_bank.accounts.length == 1) {
                    $scope.transaction.income_account = $scope.transaction.income_bank.accounts[0];
                    $scope.transaction.income_account_id = $scope.transaction.income_bank.accounts[0].id;

                    if ($scope.transaction.income_account.payment_methods.length == 1) {
                        $scope.transaction.income_payment_method_id = $scope.transaction.income_account.payment_methods[0].id;
                    }
                }
            }
        }

        $scope.onIncomeAccountChanged = function () {
            if ($scope.transaction) {
                $scope.transaction.income_account = _.find($scope.transaction.income_bank.accounts, function (account) {
                    return account.id == $scope.transaction.income_account_id;
                });

                $scope.transaction.income_payment_method_id = null;

                if ($scope.transaction.income_account && $scope.transaction.income_account.payment_methods.length == 1) {
                    $scope.transaction.income_payment_method_id = $scope.transaction.income_account.payment_methods[0].id;
                }
            }
        }

        $scope.showTransactionForm = function (transaction) {
            if (transaction) {
                $scope.transaction = angular.copy(transaction);
                $scope.transaction.date = moment(transaction.date).format('MMMM, YYYY');
                $scope.transaction.transaction_date = moment(transaction.transaction_date).format('DD/MM/YYYY');

                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });

                if ($scope.transaction.bank) {
                    $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                        return account.id == $scope.transaction.account_id;
                    });
                }

                $scope.transaction.expenseCategory = _.find($scope.expense_categories, function (expenseCategory) {
                    return expenseCategory.id == $scope.transaction.expense_category_id;
                });
            } else {
                $scope.transaction = {
                    type: 'expense'
                };
                $scope.transaction.date = moment().format('MMMM, YYYY');
                $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');
            }

            $scope.transaction.onExpenseCategoryChanged = function () {
                $scope.transaction.expenseCategory = _.find($scope.expense_categories, function (expenseCategory) {
                    return expenseCategory.id == $scope.transaction.expense_category_id;
                });
            }

            $scope.transaction.addDepartment = function (newDepartment) {
                if (!$scope.transaction.departments) {
                    $scope.transaction.departments = [];
                }
                if (!_.find($scope.transaction.departments, function (department) {
                    return department.id == newDepartment.id;
                })) {
                    $scope.transaction.departments.push({
                        id: newDepartment.id,
                        title: newDepartment.title,
                        amount: 0,
                        expense_category_id: $scope.transaction.expense_category_id
                    });
                }
            }

            $scope.transaction.deleteDepartment = function (index) {
                if (!$scope.transaction.departments) {
                    $scope.transaction.departments = [];
                }

                $scope.transaction.departments.splice(index, 1);
            }

            angular.forEach(
                angular.element("input[type='file']"),
                function (inputElem) {
                    angular.element(inputElem).val(null);
                });

            $('#modal-default').modal('show');
        }

        $scope.submitTransactionForm = function () {
            if (!$scope.transaction) return;

            if ($scope.transaction.type == 'combined_expense') {
                var hasSpecifiedAmount = _.find($scope.transaction.departments, function (department) {
                    return department.amount;
                });
                if (!hasSpecifiedAmount) {
                    error("Amount not specified");
                    return;
                }
            } else {
                $scope.transaction.departments = null;

                if (!$scope.transaction.department_id) {
                    error('Cost center not selected');
                    return;
                }

                if ($scope.transaction.type == 'expense' && !$scope.transaction.expense_category_id) {
                    error('Expense category not selected');
                    return;
                }

                if (!$scope.transaction.amount) {
                    error('Amount not specified');
                    return;
                }
            }

            if (!$scope.transaction.bank_id) {
                error('Bank not selected');
                return;
            }

            if ($scope.transaction.bank.accounts && $scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account not selected');
                return;
            }

            if ($scope.transaction.account && $scope.transaction.account.payment_methods.length > 0) {
                if (!$scope.transaction.payment_method_id && $scope.transaction.type == 'expense') {
                    error('Payment method not selected');
                    return;
                }
            }

            if (!$scope.transaction.date) {
                error('Month not selected');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            if ($scope.transaction.type == 'expense') {
                if (!$scope.transaction.paid_to) {
                    error('Paid to not specified');
                    return;
                }
            }

            var data = angular.copy($scope.transaction);
            data.date = moment($scope.transaction.date, 'MMMM/YYYY').format('YYYY-MM-DD');
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

            $scope.proccessing = true;

            if ($scope.transaction.id) {
                $http.put(_env.apiUrl + '/transactions/' + data.id, data).then(function () {
                    if ($scope.transaction.invoice) {
                        uploadInvoice(function () {
                            getTransactions();
                            $('#modal-default').modal('hide');
                        })
                    } else {
                        getTransactions();
                        $('#modal-default').modal('hide');
                    }
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                    $scope.transaction.id = response.data.id;
                    if ($scope.transaction.invoice) {
                        uploadInvoice(function () {
                            getTransactions();
                            $('#modal-default').modal('hide');
                        })
                    } else {
                        getTransactions();
                        $('#modal-default').modal('hide');
                    }
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/transactions/' + $scope.transaction.id).then(function () {
                        $('#modal-default').modal('hide');
                        $('#modal-edit-transfer').modal('hide');
                        getTransactions();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.showTransferForm = function (transaction) {
            if (transaction) {
                $scope.transaction = angular.copy(transaction);
                $scope.transaction.transaction_date = moment(transaction.transaction_date).format('DD/MM/YYYY');
                $scope.transaction.bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });

                $scope.transaction.income_bank = _.find($scope.banks, function (bank) {
                    return bank.id == $scope.transaction.bank_id;
                });

                if ($scope.transaction.bank) {
                    $scope.transaction.account = _.find($scope.transaction.bank.accounts, function (account) {
                        return account.id == $scope.transaction.account_id;
                    });
                }

                if ($scope.transaction.income_bank) {
                    $scope.transaction.income_account = _.find($scope.transaction.income_bank.accounts, function (account) {
                        return account.id == $scope.transaction.account_id;
                    });
                }

            } else {
                $scope.transaction = {
                    type: 'outgoing_transfer'
                };
                $scope.transaction.transaction_date = moment().format('DD/MM/YYYY');
            }

            angular.forEach(
                angular.element("input[type='file']"),
                function (inputElem) {
                    angular.element(inputElem).val(null);
                });

            $('#modal-edit-transfer').modal('show');
        }

        $scope.submitTransferForm = function () {
            if (!$scope.transaction) return;

            if (!$scope.transaction.type) {
                error('Transfer type not selected');
                return;
            }

            if (!$scope.transaction.bank_id) {
                error('Bank not selected');
                return;
            }

            if ($scope.transaction.bank.accounts && $scope.transaction.bank.accounts.length > 0 && !$scope.transaction.account_id) {
                error('Bank Account not selected');
                return;
            }

            if (!$scope.transaction.amount) {
                error('Amount not specified');
                return;
            }

            if (!$scope.transaction.transaction_date) {
                error('Transaction date not selected');
                return;
            }

            if (!$scope.transaction.description) {
                error('Details not specified');
                return;
            }

            if (!$scope.transaction.id && !$scope.transaction.income_bank_id) {
                error('To Bank not selected');
                return;
            }

            if (!$scope.transaction.id && $scope.transaction.income_bank.accounts && $scope.transaction.income_bank.accounts.length > 0 && !$scope.transaction.income_account_id) {
                error('To Bank Account not selected');
                return;
            }

            if (!$scope.transaction.id && !$scope.transaction.income_amount) {
                error('To Amount not specified');
                return;
            }

            var data = angular.copy($scope.transaction);
            data.date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM') + '-01';
            data.transaction_date = moment($scope.transaction.transaction_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

            $scope.proccessing = true;

            if ($scope.transaction.id) {
                $http.put(_env.apiUrl + '/transactions/' + data.id, data).then(function () {
                    if ($scope.transaction.invoice) {
                        uploadInvoice(function () {
                            getTransactions();
                            $('#modal-edit-transfer').modal('hide');
                        })
                    } else {
                        getTransactions();
                        $('#modal-edit-transfer').modal('hide');
                    }
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                    data.type = $scope.transaction.incoming_type;

                    if ($scope.transaction.invoice) {
                        $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                            $('#modal-edit-transfer').modal('hide');
                            $scope.transaction.id = response.data.id;

                            uploadInvoice(function () {
                                getTransactions();
                            })
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops... Incoming Transaction Failed',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    } else {
                        $http.post(_env.apiUrl + '/transactions', data).then(function (response) {
                            $('#modal-edit-transfer').modal('hide');
                            $scope.transaction.id = response.data.id;
                            getTransactions();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops... Incoming Transaction Failed',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    }
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        function uploadInvoice(callback) {
            if ($scope.transaction.invoice && $scope.transaction.invoice.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.transaction.invoice], _env.apiUrl + '/uploads?attachable_type=invoice&attachable_id=' + $scope.transaction.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function () {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.transaction.newInvoice = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        $scope.getInvoice = function () {
            if ($scope.transaction && $scope.transaction.newInvoice) {
                return $scope.transaction.newInvoice.local_src;
            } else if ($scope.transaction && $scope.transaction.invoice) {
                return $scope.transaction.invoice.file_url;
            } else {
                return 'img/profile.png';
            }
        }

        $scope.downloadFile = function (file) {


            var config = {responseType: 'blob'};

            $http.get(_env.apiUrl + '/uploads/download/' + file.id, config).then(function (response) {
                var headers = response.headers();

                var filename = file.file_name;
                var contentType = headers['content-type'];
                var newBlob = new Blob([response.data], {type: contentType});
                var url = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                link.dispatchEvent(clickEvent);
            });
        }

        $scope.setTransactionIdForInvoiceChange = function (tId) {
            $scope.transactionIdForInvoiceChange = tId;
        }

        $scope.invoiceChanged = function (fileInput) {
            if (!$scope.transactionIdForInvoiceChange) return;

            var transactionId = $scope.transactionIdForInvoiceChange;

            var file = fileInput.files[0];
            Uploader.uploadFilesToUrl([file], _env.apiUrl + '/uploads?attachable_type=invoice&attachable_id=' + transactionId, '', {
                'success': function () {
                    $scope.transactionIdForInvoiceChange = null;
                    getTransactions();
                },
                'error': function (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        $scope.deleteInvoice = function (transaction) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/uploads/' + transaction.invoice.id).then(function () {
                        getTransactions();
                        $scope.transaction.invoice = null;
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.exportToCsv = function () {

            var headers = [
                'Transaction_Date',
                'Month',
                'Description',
                'Type',
                'Bank',
                'Account',
                'Payment_Method',
                'Expense',
                'Cost_Center',
                'Contractor',
                'Amount',
                'Balance'
            ];

            var objects = [];

            _.forEach($scope.transactions, function (transaction) {
                objects.push({
                    Transaction_Date: transaction.transaction_date,
                    Month: transaction.date,
                    Description: transaction.description.replace('#', ''),
                    Type: transaction.type,
                    Bank: transaction.bank.title,
                    Account: transaction.account.title,
                    Payment_Method: transaction.payment_method ? transaction.payment_method.title : '',
                    Cost_Center: transaction.department ? transaction.department.title : '',
                    Expense: transaction.expense_category ? transaction.expense_category.title : '',
                    Paid_To: transaction.paid_to,
                    Amount: (round(transaction.amount) + '').replace('.', ','),
                    Balance: (round(transaction.balance) + '').replace('.', ',')
                });
            });

            var csvFilename = 'transactions_' + $scope.dateFilter.start.format('YYYY-MM-DD') + '_' + $scope.dateFilter.end.format('YYYY-MM-DD') + '.csv';
            var csvText = arr2csvText(headers, objects);
            downloadAsCSV(csvFilename, csvText)
        }

        $scope.$watch('curTab', function (newValue, oldValue) {
            if (newValue == 'list') {
                getTransactions();
            }
        });

        $scope.getActiveDepartments = function () {
            return _.filter($scope.departments, function (department) {
                return department.group == 'active';
            });
        }
    }

})();